/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$font-family: 'Poppins', sans-serif;
$main-color: #13c4a1;
$optional-color: #ff612f;
$error-color: #ff2f2f;
$black-color: #080a3c;
$white-color: #ffffff;
$paragraph-color: #4a6f8a;
$font-size: 15px;
$transition: .5s;

body {
    color: $black-color;
    background-color: $white-color;
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $font-family;
    };
}
a {
    transition: $transition;
    color: $black-color;
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        text-decoration: none;
        color: $main-color;
    }
}
button, input {
    outline: 0 !important;
}
img {
    max-width: 100%;
    height: auto;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.bg-f4f6fc {
    background-color: #f4f6fc;
}
.bg-f8fbfa {
    background-color: #f8fbfa;
}
.bg-f4f5fe {
    background-color: #f4f5fe;
}
.bg-f4e9da {
    background-color: #f4e9da;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-20 {
    padding: {
        top: 20px;
        bottom: 20px;
    };
}
.pt-20 {
    padding-top: 20px;
}
.pb-20 {
    padding-bottom: 20px;
}

.pt-10 {
    padding-top: 10px;
}
.pb-10 {
    padding-bottom: 10px;
}

p {
    color: $paragraph-color;
    margin-bottom: 12px;
    line-height: 1.8;
    font: {
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}

.separator {
    color: #6c757d;
    text-align: center;
    font-size: 18px;
    position: relative;
    margin: 10px 0;

    &::before, &::after {
        content: "";
        background-color: #6c757d;
        position: absolute;
        top: 60%;
        height: 1px;
        width: 42%;
    }
    &::before {
        left: 0;
    }
    &::after {
        right: 0;
    }
}


.otp-field {
    flex-direction: row;
    column-gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .otp-field input:focus {
    box-shadow: 0 2px 4px rgba(19, 196, 161, 1);
  }
  
  .otp-field input::-webkit-inner-spin-button,
  .otp-field input::-webkit-outer-spin-button {
    display: none;
  }

.w-fit-content {
    width: fit-content;
}

// #content {
//     min-height: max(calc(100vh - 430px), 600px);
// }
/*section-title*/
.section-title {
    text-align: center;
    max-width: 575px;
    margin: {
        left: auto;
        bottom: 60px;
        right: auto;
    };
    h2 {
        margin-bottom: 0;
        font: {
            size: 40px;
            weight: 500;
        };
        span {
            display: inline-block;
            color: $optional-color;
        }
    }
    &.text-start {
        text-align: start;
        max-width: 576px;
        margin: {
            left: 0;
            right: 0;
        };
        .sub-title {
            font-weight: 600;
            color: $optional-color;
            display: block;
            margin-bottom: 10px;
        }
    }
}
/*default-btn&optional-btn*/
.default-btn {
    cursor: pointer;
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    background-color: $optional-color;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 500;
        size: $font-size;
    };
    padding: {
        left: 50px;
        right: 25px;
        top: 13px;
        bottom: 13px;
    };
    i {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        transition: $transition;
        color: $black-color;
    }
    i.rotateme {
        top: inherit;
        animation-duration: 2s;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $main-color;
        transition: width 0.6s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover, &:focus {
        color: $white-color;
        box-shadow: 0px 5px 28.5px 1.5px rgba(19, 196, 161, 0.3) !important;

        span {
            width: 225%;
            height: 562.5px;
        }
    }
}

.default-btn.btn-disabled, .default-btn.disabled, .default-btn:disabled {
    cursor: not-allowed;
    background-color: darken($optional-color, 20%);

    span {
        background-color: $error-color;
    }
}



.optional-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $black-color;
    background-color: transparent;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 500;
        size: $font-size;
    };
    padding: {
        left: 55px;
        right: 30px;
        top: 13px;
        bottom: 13px;
    };
    i {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        transition: $transition;
        // color: $black-color;
    }
    i.rotateme {
        top: inherit;
        animation-duration: 2s;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid $black-color;
        transition: $transition;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $optional-color;
        transition: width 0.6s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover, &:focus {
        color: $white-color;
        background-color: $black-color;

        &::before {
            border-color: $optional-color;
        }
        span {
            width: 225%;
            height: 562.5px;
        }
    }
}

/*================================================
Bootstrap Area CSS
=================================================*/
.form-floating > label {
    margin-left: 10px !important;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader-area {
    position: fixed;
    top: 0;
    background-color: $optional-color;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
    display: none;

    .spinner {
        .disc {
            border: 0.3em dotted $white-color;
        }
        &::before {
            content: '';
            border: 2px dotted $white-color;
        }
    }
}

.loader-area {
    position: relative;
    top: 0;
    // background-color: #f4f5fe;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 150px;
    // z-index: 99999;
    text-align: center;
    display: block;

    .spinner {
        .disc {
            border: 0.3em dotted $optional-color;
        }
        &::before {
            content: '';
            border: 2px dotted $optional-color;
        }
    }
}

.preloader-area, .loader-area{
    .spinner {
        width: 4em;
        height: 4em;
        transform: perspective(20em) rotateX(-24deg) rotateY(20deg) rotateZ(30deg);
        transform-style: preserve-3d;
        position: absolute;
        left: 0;
        right: 0;
        top: 45%;
        transform: translateY(-45%);
        margin: {
            left: auto;
            right: auto;
        };
        .disc {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            // border: 0.3em dotted $white-color;

            &:nth-child(1) {
                animation: rotate 12s linear infinite;
            }
            &:nth-child(2) {
                animation: rotateDisc2 12s linear infinite;
            }
            &:nth-child(3) {
                animation: rotateDisc3 12s linear infinite;
            }
        }
        .inner {
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            animation: sphereSpin 6s linear infinite;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            right: 0;
            bottom: 0;
            // border: 2px dotted $white-color;
            margin: -15px;
        }
    }
}
@keyframes sphereSpin {
    0% {
        transform: rotateX(360deg) rotateY(0deg);
    }
    100% {
        transform: rotateX(0deg) rotateY(360deg);
    }
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes rotateDisc2 {
    from {
        transform: rotateX(90deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(90deg) rotateZ(360deg);
    }
}
@keyframes rotateDisc3 {
    from {
        transform: rotateY(90deg) rotateZ(0deg);
    }
    to {
        transform: rotateY(90deg) rotateZ(360deg);
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area {
    position: absolute;
    // top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, .75);
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    height: auto;
    z-index: 999;
    padding: {
        top: 5px;
        bottom: 5px;
    };
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding: {
            top: 0;
            bottom: 0;
        };
        .cointips-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            color: $black-color;
                        }
                    }
                }

                .others-options {
                    .default-btn, .optional-btn {
                      white-space: nowrap;
                    }

                    .optional-btn {
                        color: $black-color;

                        i {
                            color: $black-color;
                        }
                        &::before {
                            border-color: $black-color;
                        }
                        &:hover, &:focus {
                            color: $white-color;

                            i {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-style-two {
        position: relative;
        background-color: $white-color;

        &.is-sticky {
            position: fixed;
        }
        .cointips-nav {
            .navbar {
                .navbar-nav {
                    margin: {
                        left: auto;
                    };
                }
                .others-options {
                    margin-left: 25px;

                    .optional-btn {
                        color: $black-color;

                        i {
                            color: $optional-color;
                        }
                        &::before {
                            border-color: $black-color;
                        }
                        &:hover, &:focus {
                            color: $white-color;

                            i {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        .container {
            max-width: 1320px;
        }
    }
}
.cointips-nav {
    background-color: transparent;

    .navbar {
        background-color: transparent;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        };
        .navbar-brand {
            height: 80px;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin-left: 110px;

            .nav-item {
                position: relative;
                padding: 0;

                a {
                    font: {
                        size: $font-size;
                        weight: 500;
                    };
                    color: $black-color;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 30px;
                        bottom: 30px;
                    };
                    margin: {
                        left: 13px;
                        right: 13px;
                    };
                    &:hover, &:focus, &.active {
                        color: $main-color !important;
                    }
                    i {
                        font-size: 16px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -3px;
                            right: -3px;
                        };
                    }
                    &::after {
                        display: none !important;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;
                    border-top: 2px solid $main-color;
                    padding: 0;

                    li {
                        padding: 0;

                        a {
                            padding: 13px 20px 11px;
                            margin: 0;
                            position: relative;
                            color: #4a6f8a;
                            border-bottom: 1px dashed #e5e5e5;
                            font: {
                                size: 14px;
                                weight: 500;
                            };
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        &:last-child {
                            a {
                                border-bottom: none;
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 15px;
                            opacity: 0;
                            visibility: hidden;

                            li {
                                a {
                                    color: #4a6f8a;

                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 15px;
                                    opacity: 0;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: #4a6f8a;

                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 15px;
                                            opacity: 0;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: #4a6f8a;

                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 15px;
                                                    opacity: 0;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: #4a6f8a;

                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 15px;
                                                            opacity: 0;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: $black-color;

                                                                    &:hover, &:focus, &.active {
                                                                        color: $white-color;
                                                                        background-color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 15px;
                                                                    opacity: 0;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: $black-color;

                                                                            &:hover, &:focus, &.active {
                                                                                color: $white-color;
                                                                                background-color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $white-color;
                                                                        background-color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: 0;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: 0;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: 0;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: 0;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-options {
            margin-left: auto;

            .default-btn {
                white-space: nowrap;
                color: $white-color;
                background-color: $main-color;

                i {
                    color: $white-color;
                }
                span {
                    background-color: $black-color;
                }
            }
            .optional-btn {
              white-space: nowrap;
                margin-left: 10px;
                color: $black-color;

                // i {
                //     color: $black-color;
                // }
                &::before {
                    border-width: 2px;
                    border-color: $black-color;
                }
                span {
                    background-color: $black-color;
                }
                &:hover, &:focus {
                    color: $white-color;
                    
                    &::before {
                        border-color: $black-color;
                    }
                }
            }
            &:hover {
                i {
                    color: $white-color;
                }
            }
        }
    }
}
.navbar-light {
    .navbar-toggler {
        color: $black-color;
        border-radius: 0;
        box-shadow: unset;
        border-color: $black-color;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}
// Mobile Navbar
@media only screen and (max-width: 767px) {

    .navbar-area {
        background-color: $white-color;
        position: relative;
        // border-bottom: 1px solid #eeeeee;
        padding: {
            top: 15px;
            bottom: 15px;
        };
        &.is-sticky {
            padding: {
                top: 15px;
                bottom: 15px;
            };
        }
    }
    .cointips-nav {
        .navbar {
            .navbar-nav {
                margin-left: 0;
                max-height: 60vh;
                padding: 12px 20px;
                overflow: {
                    y: scroll;
                    x: hidden;
                };
                .nav-item {
                    padding: {
                        top: 10px;
                        bottom: 10px;
                    };
                    a {
                        margin: {
                            left: 0;
                            right: 0;
                        };
                        padding: {
                            top: 0;
                            bottom: 0;
                        };
                    }
                    .dropdown-menu {
                        top: 0 !important;
                        left: 0!important;
                        right: 0!important;
                        position: relative;
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                        margin-top: 15px !important;
                        box-shadow: unset;
                        display: block;
                        border: none;
                        background-color: #f9f9f9;

                        &.show {
                            display: block;
                            top: 0 !important;
                        }
                        li {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0 !important;
                                background-color: $white-color;
                            }
                        }
                    }
                }
            }
            .others-options {
                margin-left: 0 !important;
                padding: 0 20px 12px;

                .default-btn {
                    display: block;
                    width: 100%;
                    margin-right: 0;
                }
                .optional-btn {
                    color: $black-color;
                    display: block;
                    width: 100%;
                    margin: {
                        left: 0;
                        top: 10px;
                    };
                    i {
                        color: $black-color;
                    }
                    &::before {
                        border-color: $black-color;
                    }
                    span {
                        background-color: $black-color;
                    }
                    &:hover, &:focus {
                        color: $white-color;

                        &::before {
                            border-color: $black-color;
                        }
                        i {
                            color: $white-color;
                        }
                    }
                }
            }
            #navbarSupportedContent {
                margin-top: 15px;
                border: 1px solid #eeeeee;
            }
        }
    }
}
// iPad Navbar
@media only screen and (min-width: 768px) and (max-width: 991px) {

    .navbar-area {
        background-color: $white-color;
        position: relative;
        border-bottom: 1px solid #eeeeee;
        padding: {
            top: 0;
            bottom: 0;
        };
        &.is-sticky {
            padding: {
                top: 0;
                bottom: 0;
            };
        }
    }
    .cointips-nav {
        .navbar {
            .navbar-nav {
                margin-left: auto;

                .nav-item {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .others-options {
                margin-left: 25px;

                /*.optional-btn {
                  display: none;
                }*/
            }
        }
    }
}

.form-check {
    text-align: justify;
}

.form-check-input {
    cursor: pointer;
    color: $paragraph-color;
    outline: 0;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    margin-top: 2px;
    border-radius: 5px;
    margin-right: 5px;

    &.ng-invalid {
        border-color: rgb(220, 53, 69);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }
}
.form-check-label {
    cursor: pointer;
    margin-bottom: 0;
    color: $paragraph-color;
    display: inline;
    text-align: justify;

    a {
        color: $main-color;

        &:hover {
            color: $optional-color;
        }
    }
}
/*================================================
Partner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
        .row {
            margin: {
                left: 0;
                right: 0;
            };
            .col-lg-5, .col-lg-7 {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
}
.banner-section {
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
        .row {
            margin: {
                left: 0;
                right: 0;
            };
            .col-lg-7, .col-lg-5 {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
}
.main-banner-content {
    width: 100%;
    height: 100%;

    .content {
        max-width: 755px;
        padding-right: 220px;
        margin: {
            left: auto;
            top: 80px;
        };
        h1 {
            font: {
                size: 57px;
                weight: 500;
            };
            span {
                display: inline-block;
                color: $optional-color;
            }
        }
        p {
            margin-top: 18px;
            max-width: 540px;
        }
        .default-btn {
            margin-top: 15px;
            background-color: $black-color;

            i {
                color: $optional-color;
            }
        }
    }
}
.banner-content {
    padding: {
        top: 215px;
        bottom: 125px;
    };
    .content {
        max-width: 755px;
        padding-right: 220px;
        margin: {
            left: auto;
        };
        h1 {
            font: {
                size: 57px;
                weight: 500;
            };
            span {
                display: inline-block;
                color: $optional-color;
            }
        }
        p {
            margin-top: 18px;
            max-width: 540px;
        }
        .default-btn {
            margin-top: 15px;
            background-color: $black-color;

            i {
                color: $optional-color;
            }
        }
    }
}
.banner-image {
    height: 100vh;
    position: relative;
    border-radius: 0 0 0 80px;
    background: {
        color: $optional-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
    &.bg-1 {
        background: {
            image: url(assets/img/banner-img1.jpg);
        };
    }
    &.bg-2 {
        background: {
            image: url(assets/img/banner-img2.jpg);
        };
    }
    &.bg-3 {
        background: {
            image: url(assets/img/banner-img3.jpg);
        };
    }
    &.mbanner-bg-one {
        background: {
            image: url(assets/img/banner-img2.jpg);
        };
        .animate-banner-image {
            padding: 15px;
            margin-top: 65px;
        }
    }
    &.banner-slider-bg1 {
        background: {
            image: url(assets/img/banner-slider/banner-img1.jpg) !important;
        };
    }
    &.banner-slider-bg2 {
        background: {
            image: url(assets/img/banner-slider/banner-img2.jpg) !important;
        };
    }
    &.banner-slider-bg3 {
        background: {
            image: url(assets/img/banner-slider/banner-img3.jpg) !important;
        };
    }
    &.slider-bg1 {
        background: {
            image: url(assets/img/banner-slider/banner-img1.jpg);
        };
    }
    &.slider-bg2 {
        background: {
            image: url(assets/img/banner-slider/banner-img2.jpg);
        };
    }
    &.slider-bg3 {
        background: {
            image: url(assets/img/banner-slider/banner-img3.jpg);
        };
    }
}
.banner-img {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 0 0 0 80px;
    background: {
        color: $optional-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
    &.bg-1 {
        background: {
            image: url(assets/img/banner-img1.jpg);
        };
    }
    &.bg-2 {
        background: {
            image: url(assets/img/banner-img2.jpg);
        };
    }
    &.bg-3 {
        background: {
            image: url(assets/img/banner-img3.jpg);
        };
    }
}
.banner-content-slides {
    &.owl-theme {
        .owl-nav {
            text-align: start;
            margin-top: 50px;

            [class*=owl-] {
                color: $black-color;
                font-size: 25px;
                margin: 0 5px;
                background: #f4f6fc;
                border-radius: 0;
                transition: $transition;
                width: 45px;
                height: 45px;
                line-height: 52px;

                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }
                &.owl-prev {
                    border-radius: 0 0 0 10px;
                    margin-left: 0;
                }
                &.owl-next {
                    margin-right: 0;
                    border-radius: 0 10px 0 0;
                }
            }
        }
    }
}
.shape19 {
    position: absolute;
    right: 35%;
    bottom: -25px;
    z-index: -1;

    img {
        animation: moveLeftBounce 3s linear infinite;
    }
}
.shape20 {
    position: absolute;
    right: 55%;
    bottom: 5%;
    z-index: -1;

    img {
        animation: moveLeftBounce 3s linear infinite;
    }
}
.shape21 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    img {
        animation: moveBounce 5s linear infinite;
    }
}
.shape22 {
    position: absolute;
    left: 5%;
    top: 15%;
    z-index: -1;

    img {
        animation: animationFramesOne 20s infinite linear;
    }
}
.shape23 {
    position: absolute;
    left: 10%;
    bottom: 10%;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape24 {
    position: absolute;
    left: 25%;
    top: 40%;
    z-index: -1;

    img {
        animation: animationFramesOne 20s infinite linear;
    }
}
.shape25 {
    position: absolute;
    left: 40%;
    bottom: 10%;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape26 {
    position: absolute;
    left: 30%;
    top: 15%;
    z-index: -1;

    img {
        animation: moveLeftBounce 3s linear infinite;
    }
}
.chat-wrapper {
    position: absolute;
    left: -20%;
    top: 50%;
    transform: translateY(-50%);
    width: 510px;
    height: auto;

    .chat-container {
        height: 400px;
        overflow: hidden;
    }
    .chat {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .spinme-right {
        display: inline-block;
        padding: 15px 20px;
        font-size: 14px;
        border-radius: 30px;
        line-height: 1.25em;
        font-weight: 100;
        opacity: 0.5;
    }
    .spinme-left {
        display: inline-block;
        padding: 15px 20px;
        font-size: 14px;
        color: #f4f6fc;
        border-radius: 30px;
        line-height: 1.25em;
        font-weight: 100;
        opacity: 0.5;
    }
    .spinner {
        margin: 0;
        width: 30px;
        text-align: center;
    }
    .spinner > div {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        background: $black-color;
    }
    .spinner .bounce1 {
        animation-delay: -0.32s;
    }
    .spinner .bounce2 {
        animation-delay: -0.16s;
    }
    [hiddenn] {
        display: none;
    }
}
.message-left {
    text-align: start;
    position: relative;
    margin-bottom: 15px;
    padding: {
        bottom: 15px;
        left: 50px;
    };
    img {
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40px !important;
        height: 40px;
        border-radius: 50%;
    }
    .message-time {
        color: #8097b1;
        position: absolute;
        right: 13px;
        bottom: 8px;
        font: {
            size: 11px;
            weight: 400;
        };
    }
    .message-text {
        max-width: 80%;
        display: inline-block;
        background: #f4f6fc;
        padding: 15px 30px 30px 15px;
        position: relative;
        color: #2e384d;
        border-radius: 15px 15px 15px 0;
        line-height: 1.8;
        font: {
            weight: 400;
            size: 13px;
        };
        &::before {
            content: '';
            position: absolute;
            left: 3.4px;
            bottom: -5px;
            width: 10px;
            height: 10px;
            background: #f4f6fc;
            transform: rotate(45deg);
        }
    }
}
.message-right {
    text-align: end;
    margin-bottom: 15px;
    position: relative;
    padding: {
        bottom: 15px;
        right: 50px;
    };
    .message-time {
        color: #e4e8f0;
        position: absolute;
        right: 13px;
        bottom: 8px;
        font: {
            size: 11px;
            weight: 400;
        };
    }
    img {
        display: inline-block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px !important;
        height: 40px;
        border-radius: 50%;
    }
    .message-text {
        line-height: 1.8;
        display: inline-block;
        background: #2e5bff;
        padding: 15px 30px 30px 15px;
        position: relative;
        color: $white-color;
        border-radius: 15px 15px 0 15px;
        text-align: start;
        max-width: 80%;
        font: {
            weight: 400;
            size: 13px;
        };
        &::before {
            content: '';
            position: absolute;
            right: 2px;
            bottom: -5px;
            width: 10px;
            height: 10px;
            background: #2e5bff;
            transform: rotate(45deg);
        }
    }
    .spinner > div {
        background: $white-color;
    }
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px,0px) rotate(0deg) ;
    }
    20% {
        transform: translate(73px,-1px) rotate(36deg) ;
    }
    40% {
        transform: translate(141px,72px) rotate(72deg) ;
    }
    60% {
        transform: translate(83px,122px) rotate(108deg) ;
    }
    80% {
        transform: translate(-40px,72px) rotate(144deg) ;
    }
    100% {
        transform:  translate(0px,0px) rotate(0deg) ;
    }
}
.main-banner-two {
    .banner-image-slider {
        .banner-image {
            img {
                display: none;
            }
        }
        .owl-dots {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;

            .owl-dot.active span, .owl-dot:hover span {
                background-color: $main-color;
            }
        }
    }
}
.banner-img {
    &.banner-video {
        height: 100vh;
        position: relative;
        background: {
            image: url(assets/img/banner-video-bg.jpg) !important;
        };
        .video-box {
            .video-btn {
                background-color: $main-color;

                &::after, &::before {
                    border: 1px solid $main-color;
                }
                &:hover, &:focus {
                    background-color: $optional-color;

                    &::after, &::before {
                        border-color: $optional-color;
                    }
                }
            }
        }
    }
}

/* Animate banner image */
.animate-banner-image {
    position: relative;
}
.animate-banner-image {
    img {
        display: block !important;
        animation: border-transform 10s linear infinite alternate forwards;
        margin: auto;
    }
}
@keyframes border-transform {
    0%, 100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}
.about-area {
    .section-title {
        &.text-start {
            margin-bottom: 0;
        }
    }
    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
        .row {
            margin: {
                left: 0;
                right: 0;
            };
            .col-lg-6 {
                padding: {
                    left: 0;
                    right: 0;
                };
            }
        }
    }
}
.about-inner-area {
    padding: {
        top: 60px;
        bottom: 60px;
    };
}
.about-inner-image {
    height: 100%;
    background: {
        image: url(assets/img/marketing-agency/about-image.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.about-inner-content {
    background-color: #f4e9da;
    padding: 100px;
    margin: {
        top: -60px;
        bottom: -60px;
    };
    .content {
        max-width: 455px;

        h2 {
            margin-bottom: 0;
            line-height: 1.3;
            font: {
                size: 36px;
                weight: 500;
            };
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                top: 30px;
            };
            li {
                margin-bottom: 15px;
                color: $paragraph-color;
                position: relative;
                font: {
                    size: 18px;
                    weight: 500;
                };
                i {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background-color: $white-color;
                    color: $main-color;
                    margin-right: 5px;
                    border-radius: 50%;
                    font-size: 25px;
                    text-align: center;
                    transition: $transition;
                    position: relative;
                    top: 3px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-content {
    .sub-title {
        display: block;
        color: $optional-color;
        margin-bottom: 7px;
    }
    h2 {
        margin-bottom: 10px;
        font: {
            size: 40px;
            weight: 500;
        };
    }
}
.about-image {
    text-align: center;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    .container {
        max-width: 1290px;
    }
    .row {
        margin: {
            left: 0;
            right: 0;
        };
        .col-lg-3, .col-lg-9 {
            padding: {
                left: 0;
                right: 0;
            };
        }
    }
}
.partner-title {
    h3 {
        color: #8791b0;
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 500;
        };
    }
}
.single-partner-item {
    text-align: start;

    a {
        display: inline-block;

        img {
            width: auto !important;
            display: inline-block !important;
            transition: $transition;
        }
        &:hover {
            img {
                transform: scale(1.03);
            }
        }
    }
}
.partner-area-two {
    &.ptb-70 {
        padding: {
            top: 70px;
            bottom: 40px;
        };
    }
}
.single-partner-box {
    margin-bottom: 30px;
    text-align: center;
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-right-color {
        &::before {
            position: absolute;
            right: 0;
            content: '';
            top: 0;
            width: 35%;
            height: 215%;
            z-index: -1;
            background-color: $main-color;
            transform: skewY(-66deg);
        }
    }
    &.bg-left-color {
        &::before {
            position: absolute;
            left: 0;
            content: '';
            top: 0;
            width: 35%;
            height: 215%;
            z-index: -1;
            background-color: $main-color;
            transform: skewY(66deg);
        }
    }
}
.services-content {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        left: 15px;
        right: 15px;
    };
    .content {
        max-width: 615px;
        padding-left: 30px;

        .icon {
            margin-bottom: 20px;
        }
        h2 {
            font: {
                size: 40px;
                weight: 500;
            };
            span {
                display: inline-block;
                color: $optional-color;
            }
        }
        p {
            max-width: 435px;
            margin-top: 18px;
        }
        .default-btn {
            margin-top: 15px;
            background-color: $black-color;

            i {
                color: $optional-color;
            }
        }
        &.left-content {
            padding: {
                right: 30px;
                left: 0;
            };
            margin: {
                left: auto;
            };
        }
    }
}
.services-image {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        left: 15px;
        right: 15px;
    };
    .image {
        text-align: center;
    }
}
.it-service-content {
    .content {
        p {
            margin-bottom: 30px;
            max-width: 540px;
        }
    }
}
.feature-box {
    color: $paragraph-color;
    background-color: $white-color;
    border: 1px solid #f2effc;
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    border-radius: 30px;
    font-size: 15px;
    padding: 12px 22px;
    margin-bottom: 25px;
    cursor: pointer;
    transition: $transition;

    &:hover {
        background-color: $main-color;
        color: $white-color;
        padding-left: 25px;

        i {
            color: $white-color;
        }
    }

    i {
        margin-right: 3px;
        color: $main-color;
    }
}

/*================================================
Video Presentation Area CSS
=================================================*/
.video-presentation-area {
    position: relative;
    z-index: 1;
}
.video-box {
    position: relative;
    text-align: center;
    max-width: 750px;
    border-radius: 5px;
    z-index: 1;
    margin: {
        left: auto;
        right: auto;
    };
    .main-image {
        border-radius: 5px;
    }
    .video-btn {
        position: absolute;
        left: 0;
        right: 0;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
        background-color: $optional-color;
        border-radius: 5px;
        color: $white-color;
        font-size: 60px;
        z-index: 1;
        margin: {
            left: auto;
            right: auto;
        };
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 5px;
            border: 1px solid $optional-color;
            transition: $transition;
        }
        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            z-index: 1;
            transform: translateY(-50%);
            margin: {
                left: auto;
                right: auto;
            };
        }
        &::before {
            animation: ripple 2s linear infinite
        }
        &::after {
            animation: ripple 2s linear 1s infinite
        }
        &:hover, &:focus {
            background-color: $main-color;
            color: $white-color;

            &::after, &::before {
                border-color: $main-color;
            }
        }
    }
}
.shape-map1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 90%;
    transform: translateY(-90%);
    z-index: -1;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
    };
}
.shape1 {
    position: absolute;
    left: -30px;
    top: -23px;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape2 {
    position: absolute;
    right: -30px;
    top: -30px;
    z-index: -1;

    img {
        animation: moveScale 3s linear infinite;
    }
}
.shape3 {
    position: absolute;
    left: -50px;
    bottom: -50px;
    z-index: -1;

    img {
        animation: moveScale 3s linear infinite;
    }
}
.shape4 {
    position: absolute;
    right: -30px;
    bottom: -30px;
    z-index: -1;
}
.shape5 {
    position: absolute;
    right: -60px;
    bottom: -60px;
    z-index: -2;
}
.shape6 {
    position: absolute;
    left: -26px;
    top: -22px;
    z-index: -1;

    img {
        animation: moveLeftBounce 5s linear infinite;
    }
}
.shape7 {
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 10%;
    z-index: -1;

    img {
        animation: moveBounce 5s linear infinite;
    }
}
.shape8 {
    position: absolute;
    left: 12%;
    top: 60%;
    z-index: -1;
    transform: translateY(-60%);

    img {
        animation: {
            name: rotateMe;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape9 {
    position: absolute;
    right: 10%;
    top: 40%;
    z-index: -1;
    transform: translateY(-40%);

    img {
        animation: {
            name: rotateMe;
            duration: 20s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes ripple {
    0% {
        transform: scale(1)
    }
    75% {
        transform: scale(1.75);
        opacity: 1
    }
    100% {
        transform: scale(2);
        opacity: 0
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(.8);
    }
    100% {
        transform: scale(1);
    }
}

/*================================================
FunFacts Area CSS
=================================================*/
.funfacts-inner {
    max-width: 1000px;
    margin: {
        top: 140px;
        left: auto;
        right: auto;
    };
}
.single-funfacts {
    margin-bottom: 30px;
    text-align: center;

    h3 {
        color: $optional-color;
        margin-bottom: 0;
        font: {
            size: 32px;
            weight: 500;
        };
        .sign-icon {
            font-size: 28px;
        }
    }
    p {
        line-height: initial;
        margin: {
            bottom: 0;
        };
    }
}
.funfact-style-two {
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  padding: 25px 0;
  border-radius: 5px;

  i {
    font-size: 35px;
    color: #13c4a1;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: auto;
    position: relative;
    margin-bottom: 15px;
    background: #F6F4FD;
    border-radius: 100%;
    text-align: center;
  }

  h3 {
    margin-bottom: 3px;
  }
}
.contact-cta-box {
    max-width: 750px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    padding: 30px 230px 30px 50px;
    position: relative;
    margin: {
        left: auto;
        top: 40px;
        right: auto;
    };
    h3 {
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 500;
        };
    }
    p {
        line-height: initial;
        margin: {
            top: 6px;
            bottom: 0;
        };
    }
    .default-btn {
        background-color: $main-color;
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);

        i {
            color: $optional-color;
        }
        span {
            background-color: $optional-color;
        }
        &:hover, &:focus {
            i {
                color: $black-color;
            }
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.single-features-box {
    text-align: center;
    margin: {
        bottom: 30px;
        top: 45px;
    };
    .icon {
        background-color: $main-color;
        color: $white-color;
        position: relative;
        z-index: 1;
        width: 85px;
        height: 85px;
        font-size: 45px;
        transition: $transition;
        bottom: 45px;
        margin: {
            left: auto;
            right: auto;
        };
        &::before {
            width: 100%;
            height: 100%;
            right: -15px;
            bottom: -15px;
            border: 5px solid $main-color;
            content: '';
            position: absolute;
            transition: $transition;
        }
        i {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h3 {
        margin-bottom: 10px;
        font: {
            size: 22px;
            weight: 500;
        };
    }
    &:hover {
        .icon {
            animation: bounce 2s;
            bottom: 25px;

            &::before {
                right: 0;
                bottom: 0;
            }
        }
    }
}
.features-box {
    overflow: hidden;
    border: 1px solid #f4f5fe;
    padding: 30px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    transition: $transition;
    border-radius: 5px;

    .icon {
        width: 85px;
        height: 85px;
        background-color: #bce9e5;
        color: $main-color;
        text-align: center;
        font-size: 45px;
        position: relative;
        border-radius: 10px;
        margin-bottom: 22px;
        transition: $transition;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    h3 {
        margin-bottom: 10px;
        font: {
            size: 22px;
            weight: 500;
        };
    }
    p {
        margin-bottom: 0;
    }
    .back-icon {
        position: absolute;
        right: 10px;
        top: -52px;
        z-index: -1;
        color: $main-color;
        opacity: .06;
        font-size: 190px;
        line-height: 190px;
        transition: $transition;
    }
    &:hover {
        background-color: $white-color;
        border-radius: 0;
        border-color: $white-color;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        transform: translateY(-10px);

        .icon {
            background-color: $main-color;
            color: $white-color;
        }
        .back-icon {
            opacity: 0;
            visibility: hidden;
        }
    }

    .read-more {
        margin-top: 5px;
        color: $main-color;
        display: inline-block;
        i {
            color: $main-color;
            width: 0;
            height: 0;
            line-height: 1;
            font-size: 21px;
            margin: 0;
            top: 4px;
            position: relative;
        }
        &:hover {
            letter-spacing: 1.1px;
        }
    }
}
.col-lg-4 {
    &:nth-child(1) {
        .single-features-box {
            .icon {
                background-color: #ff612f;

                &::before {
                    border-color: #ff612f;
                }
            }
        }
        .features-box {
            .icon {
                background-color: #ffd7cb;
                color: $optional-color;
            }
            .back-icon {
                color: $optional-color;
            }
            &:hover {
                .icon {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
    &:nth-child(3) {
        .single-features-box {
            .icon {
                background-color: #080a3c;

                &::before {
                    border-color: #080a3c;
                }
            }
        }
        .features-box {
            .icon {
                background-color: #b2b3c3;
                color: #080a3c;
            }
            .back-icon {
                color: #080a3c;
            }
            &:hover {
                .icon {
                    background-color: #080a3c;
                    color: $white-color;
                }
            }
        }
    }
    &:nth-child(4) {
        .single-features-box {
            .icon {
                background-color: #fc4c86;

                &::before {
                    border-color: #fc4c86;
                }
            }
        }
        .features-box {
            .icon {
                background-color: #fed2e1;
                color: #fc4c86;
            }
            .back-icon {
                color: #fc4c86;
            }
            &:hover {
                .icon {
                    background-color: #fc4c86;
                    color: $white-color;
                }
            }
        }
    }
    &:nth-child(5) {
        .single-features-box {
            .icon {
                background-color: #ffb300;

                &::before {
                    border-color: #ffb300;
                }
            }
        }
        .features-box {
            .icon {
                background-color: #ffecbf;
                color: #ffb300;
            }
            .back-icon {
                color: #ffb300;
            }
            &:hover {
                .icon {
                    background-color: #ffb300;
                    color: $white-color;
                }
            }
        }
    }
    &:nth-child(6) {
        .single-features-box {
            .icon {
                background-color: #286efa;

                &::before {
                    border-color: #286efa;
                }
            }
        }
        .features-box {
            .icon {
                background-color: #bcd2fd;
                color: #286efa;
            }
            .back-icon {
                color: #286efa;
            }
            &:hover {
                .icon {
                    background-color: #286efa;
                    color: $white-color;
                }
            }
        }
    }
}
.single-features-card {
    background-color: $white-color;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.03);
    border-radius: 35px;
    padding: 30px;
    transition: $transition;
    position: relative;
    margin-bottom: 30px;

    &:hover {
        box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.08);
        transform: translateY(-10px);
    }

    i {
        font-size: 30px;
        color: $main-color;
        width: 70px;
        height: 70px;
        line-height: 70px;
        margin: auto;
        position: relative;
        margin-bottom: 20px;
        background: #F6F4FD;
        border-radius: 100%;
        text-align: center;
    }
    h3 {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 500;
        a {
            color: $black-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    p {
        margin: 0;
    }
}
.features-box-one {
    margin-bottom: 30px;
    background: $white-color;
    padding: 30px 40px;
    text-align: center;
    border-radius: 10px;
    transition: $transition;

    &:hover {
        box-shadow: 0 5px 50px rgba(0, 0, 0, 0.08);
        margin-top: -5px;
    }

    i {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 30px;
        text-align: center;
        border-radius: 100%;
        color: $white-color;
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: 500;
    }
    p {
        margin: 0;
    }
    .read-more {
        margin-top: 5px;
        color: $main-color;
        display: inline-block;
        i {
            color: $main-color;
            width: 0;
            height: 0;
            line-height: 1;
            font-size: 21px;
            margin: 0;
            top: 4px;
            position: relative;
        }
        &:hover {
            letter-spacing: 1.1px;
        }
    }
}
.bg-13c4a1 {
    background-color: $main-color;
}
.bg-6610f2 {
    background-color: #6610f2;
}
.bg-ffb700 {
    background-color: #ffb700;
}
.bg-fc3549 {
    background-color: #fc3549;
}
.bg-00d280 {
    background-color: #00d280;
}
.bg-ff612f {
    background-color: #ff612f;
}
.bg-9c27b0 {
    background-color: #9c27b0;
}
.bg-3f51b5 {
    background-color: #3f51b5;
}
.bg-e91e63 {
    background-color: #e91e63;
}

/*================================================
Team Area CSS
=================================================*/
.single-team-box {
    margin-bottom: 30px;
    text-align: center;

    .image {
        border: 4px solid #bce9e5;
        padding: 10px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        transition: $transition;

        img {
            border-radius: 10px;
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            };
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 3px;
                    right: 3px;
                };
                a {
                    display: block;
                    width: 33px;
                    height: 33px;
                    background-color: #f4f5fe;
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: $black-color;
                    border-radius: 2px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                &:nth-child(4) {
                    transition-delay: 0.4s;
                }
            }
        }
    }
    .content {
        margin-top: 20px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 500;
            };
        }
        span {
            display: block;
            color: $main-color;
            font-size: 14px;
            margin-top: 6px;
        }
    }
    &:hover {
        .image {
            border-color: $main-color;
            background-color: #f4f5fe;

            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
.col-lg-4 {
    &:nth-child(1), &:nth-child(7) {
            .single-team-box {
                .image {
                    border-color: #ffdacf;

                    .social {
                        li {
                            a {
                                &:hover {
                                    color: $white-color;
                                    background-color: $optional-color;
                                }
                            }
                        }
                    }
                }
                .content {
                    span {
                        color: $optional-color;
                    }
                }
                &:hover {
                    .image {
                        border-color: $optional-color;
                    }
                }
            }
    }
    &:nth-child(3), &:nth-child(5) {
        .single-team-box {
            .image {
                border-color: #b2b3c3;

                .social {
                    li {
                        a {
                            &:hover {
                                color: $white-color;
                                background-color: #080a3c;
                            }
                        }
                    }
                }
            }
            .content {
                span {
                    color: #080a3c;
                }
            }
            &:hover {
                .image {
                    border-color: #080a3c;
                }
            }
        }
    }
    &:nth-child(4), &:nth-child(6) {
        .single-team-box {
            .image {
                border-color: #fed2e1;

                .social {
                    li {
                        a {
                            &:hover {
                                color: $white-color;
                                background-color: #fc4c86;
                            }
                        }
                    }
                }
            }
            .content {
                span {
                    color: #fc4c86;
                }
            }
            &:hover {
                .image {
                    border-color: #fc4c86;
                }
            }
        }
    }
}
.team-area-two {
    .section-title {
        margin-bottom: -60px;
    }
}
.single-team-member {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    .content {
        background-color: #f9f9f9;
        text-align: start;
        padding: 30px 20px 15px;
        position: relative;

        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 500;
            };
        }
        span {
            margin-top: 6px;
            display: block;
            color: $paragraph-color;
        }
        i {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: $optional-color;
            transition: $transition;
            color: $white-color;
            border-radius: 2px;
            position: absolute;
            right: 20px;
            top: -16px;
            font-size: 18px;
            display: inline-block;
        }
    }
    .image {
        position: relative;

        .social-link {
            position: absolute;
            right: 20px;
            bottom: 25px;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    color: $black-color;
                    background-color: $white-color;
                    font-size: 18px;
                    position: relative;
                    border-radius: 2px;
                    transform: scaleY(0);
                    display: inline-block;
                    transition: .4s;

                    i {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        right: 0;
                        transform: translateY(-48%);
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                    a {
                        transform: scaleX(0);
                    }
                }
            }
        }
    }
    &:hover {
        .content {
            i {
                background-color: $black-color;
                color: $white-color;
            }
        }
        .image {
            .social-link {
                li {
                    a {
                        transform: scale(1) !important;
                    }
                }
            }
        }
    }
}
.col-lg-3 {
    &:nth-child(1) {
        .single-team-member {
            margin-top: 150px;
        }
    }
    &:nth-child(2) {
        .single-team-member {
            margin-top: 100px;
        }
    }
    &:nth-child(3) {
        .single-team-member {
            margin-top: 50px;
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    overflow: hidden;
}
.single-feedback-item {
    position: relative;
    margin: {
        top: 120px;
        bottom: 30px;
    };
    img {
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: 0;
        display: inline-block !important;
        width: auto !important;
    }
    .feedback-desc {
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-left: 45px;
        background-color: $white-color;
        box-shadow: 0 10px 35px 5px rgba(137, 173, 255, 0.15);
        padding: 30px 30px 30px 160px;

        p {
            margin-bottom: 0;
        }
        .rating {
            margin: {
                top: 13px;
                bottom: 15px;
            };
            i {
                font-size: 17px;
                display: inline-block;
                margin-right: -1px;
                color: #ff612f;
            }
        }
        .client-info {
            h3 {
                margin-bottom: 0;
                font: {
                    size: 18px;
                    weight: 500;
                };
            }
            span {
                display: block;
                color: $main-color;
                font-size: 14px;
                margin-top: 6px;
            }
        }
        &::before {
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: -1;
            color: #f5f5f5;
            line-height: 95px;
            content: "\ee81";
            font: {
                family: "boxicons" !important;
                weight: normal;
                style: normal;
                variant: normal;
                size: 135px;
            };
        }
    }
}
.feedback-slides {
    &.owl-theme {
        .owl-stage-outer {
            padding: {
                left: 30px;
                right: 30px;
            };
            margin: {
                left: -30px;
                right: -30px;
            };
        }
        .owl-item {
            .single-feedback-item {
                margin-bottom: 40px;

                .feedback-desc {
                    box-shadow: unset;
                }
            }
            &.active {
                .single-feedback-item {
                    .feedback-desc {
                        box-shadow: 0 10px 35px 5px rgba(137, 173, 255, 0.15);
                    }
                }
            }
        }
        .owl-nav {
            &.disabled+.owl-dots {
                line-height: initial;
                margin: {
                    bottom: 30px;
                    top: 10px;
                };
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 3px;
                    background: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $main-color;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 50%;
                        margin: 2px;
                        background-color: $optional-color;
                        transform: scale(0);
                        transition: $transition;
                    }
                }
                &.active, &:hover {
                    span {
                        &::before {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
.testimonials-area {
    background-color: $main-color;

    .section-title {
        h2 {
            color: $white-color;
        }
    }
}
.single-testimonials-item {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -ms-flex-align: center!important;
    align-items: center!important;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 40px 30px;
    background-color: $white-color;

    .client-info {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
        text-align: center;

        img {
            border: 2px solid #f4f5fe;
            padding: 5px;
            border-radius: 5px;
            width: auto !important;
            display: inline-block !important;
        }
        h3 {
            margin: {
                bottom: 0;
                top: 12px;
            };
            font: {
                size: 18px;
                weight: 500;
            };
        }
        span {
            display: block;
            color: $main-color;
            font-size: 14px;
            margin-top: 5px;
        }
    }
    .testimonials-desc {
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        padding-left: 15px;
        max-width: 70%;

        p {
            margin-bottom: 0;
        }
        .rating {
            margin: {
                top: 13px;
            };
            i {
                font-size: 17px;
                display: inline-block;
                margin-right: -1px;
                color: #ff612f;
            }
        }
    }
    &::before {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: -1;
        color: #f5f5f5;
        line-height: 95px;
        content: "\ed67";
        font: {
            family: "boxicons" !important;
            weight: normal;
            style: normal;
            variant: normal;
            size: 135px;
        };
    }
}
.testimonials-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                line-height: initial;
                margin: {
                    bottom: 30px;
                    top: 10px;
                };
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 14px;
                    height: 14px;
                    margin: 0 3px;
                    background: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $white-color;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 50%;
                        margin: 2px;
                        background-color: $white-color;
                        transform: scale(0);
                        transition: $transition;
                    }
                }
                &.active, &:hover {
                    span {
                        &::before {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}
.feedback-area {
    .section-title {
        &.text-start {
            margin-bottom: 0;
        }
    }
}
.single-testimonials-box {
    position: relative;
    z-index: 1;

    p {
        font-size: 15px;
        color: $black-color;
    }
    .client-info {
        margin-top: 25px;

        img {
            width: 60px;
            height: 60px;
            display: inline-block;
            border-radius: 50%;
        }
        .title {
            margin-left: 15px;

            h3 {
                margin-bottom: 0;
                color: $optional-color;
                font: {
                    size: 18px;
                    weight: 500;
                };
            }
            span {
                display: block;
                margin-top: 5px;
            }
        }
    }
    &::before {
        content: "\ee81";
        font-family: 'boxicons'!important;
        position: absolute;
        right: 200px;
        bottom: -12px;
        color: #fff1ed;
        line-height: 1;
        font-size: 90px;
        z-index: -1;
    }
}
.testimonials-slides-two {
    &.owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 0;
                text-align: start;
            }
        }
        .owl-dots {
            margin: {
                top: 30px !important;
                left: 2px;
            };
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 8px 0 0;
                    background: transparent;
                    border: 1px solid #fe9b81;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $optional-color;
                        border-radius: 50%;
                        margin: 2px;
                        opacity: 0;
                        transition: $transition;
                        visibility: hidden;
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: $optional-color;

                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
.bg-f9f9f9 {
    background-color: #f9f9f9;
}

/*================================================
Pricing Area CSS
=================================================*/
.single-pricing-table {
    margin-bottom: 30px;
    text-align: center;
    background-color: $white-color;
    padding-bottom: 30px;
    border-radius: 5px;
    transition: $transition;

    .pricing-header {
        padding: {
            top: 25px;
            bottom: 20px;
        };
        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 500;
            };
        }
    }
    .price {
        transition: $transition;
        padding: {
            top: 8px;
            bottom: 5px;
        };
        border: {
            top: 2px dashed #f4f5fe;
            bottom: 2px dashed #f4f5fe;
        };
        font: {
            weight: 600;
            size: 40px;
        };
        sup {
            top: -18px;
            font: {
                size: 18px;
                weight: 500;
            };
        }
        sub {
            bottom: 2px;
            font: {
                size: 17px;
                weight: 500;
            };
        }
    }
    .pricing-features {
        padding-left: 0;
        text-align: start;
        list-style-type: none;
        margin: {
            top: 30px;
            bottom: 0;
            left: 50px;
            right: 50px;
        };
        li {
            margin-bottom: 10px;
            position: relative;
            color: #444683;
            padding-left: 21px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 3px;
            }
            span {
                display: inline-block;
                color: #b7bad2;
                font-size: 20px;
                position: absolute;
                top: 1px;
                margin-left: 3px;
            }
        }
    }
    .btn-box {
        margin-top: 30px;
    }
    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        transform: translateY(-10px);
    }
}
.pricing-list-tab {
    .tabs {
        text-align: center;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 40px;

        li {
            display: inline-block;

            a {
                display: block;
                background-color: $white-color;
                color: #8d8fb4;
                padding: 10px 30px 10px 53px;
                position: relative;
                border-radius: 5px;
                margin: {
                    left: -1px;
                    right: -1px;
                };
                font: {
                    size: 16px;
                    weight: 500;
                };
                i {
                    color: $optional-color;
                    margin-right: 2px;
                    position: absolute;
                    font-size: 18px;
                    top: 48%;
                    transform: translateY(-48%);
                    left: 30px;
                }
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
            &:nth-child(1) {
                a {
                    border-radius: 30px 0 0 30px;
                }
            }
            &:nth-child(2) {
                a {
                    border-radius: 0 30px 30px 0;
                }
            }
        }
    }
}
.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.col-lg-4 {
    &:nth-child(1) {
        .single-pricing-table {
            .btn-box {
                .default-btn {
                    background-color: $black-color;

                    i {
                        color: $optional-color;
                    }
                }
            }
        }
    }
    &:nth-child(3) {
        .single-pricing-table {
            .btn-box {
                .default-btn {
                    background-color: $main-color;

                    i {
                        color: $optional-color;
                    }
                    span {
                        background-color: $optional-color;
                    }
                    &:hover, &:focus {
                        i {
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
    margin-left: auto;
    max-width: 775px;

    h2 {
        margin-bottom: 40px;
        font: {
            size: 40px;
            weight: 500;
        };
        span {
            display: inline-block;
            color: $optional-color;
        }
    }
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
            bottom: 0;
        };
        .accordion-item {
            display: block;
            background: #f5f5ff;
            margin-bottom: 10px;
            border-radius: 0;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 12px 40px 10px 20px;
            color: $black-color;
            position: relative;
            display: block;
            font: {
                size: 16px;
                weight: 500;
            };
            i {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: $black-color;
                font-size: 20px;
                transition: $transition;
            }
            &.active {
                i {
                    &::before {
                        content: "\eb8b";
                        color: $optional-color;
                    }
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            padding: 15px;
            font-size: 14px;
            border-top: 1px solid $optional-color;
            color: $paragraph-color;

            &.show {
                display: block;
            }
        }
    }
}
.faq-image {
    text-align: center;
}
.bg-color-f4f5fe {
    background-color: #f4f5fe;

    .faq-accordion {
        .accordion {
            .accordion-item {
                background: $white-color;
            }
        }
    }
}

/*================================================
App Download Area CSS
=================================================*/
.app-download-area {
    background-color: #f4f5fe;
    background: {
        image: url(assets/img/white-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
}
.app-download-image {
    text-align: center;
}
.app-download-content {
    .sub-title {
        display: inline-block;
        background-color: $main-color;
        color: $white-color;
        padding: 5px 25px;
        border-radius: 35px;
        margin-bottom: 15px;
        font: {
            size: 14px;
            weight: 400;
        };
    }
    h2 {
        font: {
            size: 40px;
            weight: 500;
        };
    }
    .btn-box {
        margin-top: 30px;

        .play-store-btn {
            display: inline-block;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            padding: 12px 25px 12px 74px;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            color: #666786;
            font: {
                size: 13px;
                weight: 400;
            };
            span {
                display: block;
                color: $black-color;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            img {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover, &:focus {
                transform: translateY(-5px);
            }
        }
        .apple-store-btn {
            margin-right: 8px;
            display: inline-block;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            padding: 12px 25px 12px 74px;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            color: #666786;
            font: {
                size: 13px;
                weight: 400;
            };
            span {
                display: block;
                color: $black-color;
                font: {
                    size: 20px;
                    weight: 600;
                };
            }
            img {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover, &:focus {
                transform: translateY(-5px);
            }
        }
    }
}

/*================================================
Our Loving Clients Area CSS
=================================================*/
.clients-logo-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin: {
        right: -5px;
        left: -5px;
        top: -10px;
    };
}
.single-clients-logo {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding: {
        left: 5px;
        right: 5px;
        top: 10px;
    };
    a {
        text-align: center;
        display: block;
        background-color: $white-color;
        padding: 30px 20px;
        transition: $transition;

        img {
            transition: $transition;
        }
        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            transform: translateY(-10px);

            img {
                transform: scale(1.1);
            }
        }
    }
}
.bg-color-f8fbfa {
    .single-clients-logo {
        a {
            background-color: #f8fbfa;

            &:hover {
                background-color: $white-color;
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    border-radius: 2px;
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    transition: $transition;

    .post-image {
        overflow: hidden;
        position: relative;

        a {
            display: block;

            img {
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $white-color;
            border-radius: 0 5px 0 0;
            padding: 10px 25px 0;
            z-index: 1;
            color: $black-color;
            font: {
                size: $font-size;
                weight: 300;
            };
            i {
                color: $optional-color;
                margin-right: 1px;
                font-size: 16px;
                position: relative;
                top: 1px;
            }
        }
    }
    .post-content {
        padding: 25px;

        h3 {
            line-height: 1.4;
            font: {
                size: 22px;
                weight: 500;
            };
            a {
                display: inline-block;
            }
        }
        .post-info {
            -ms-flex-align: center!important;
            align-items: center!important;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: {
                top: 15px;
            };
            .post-by {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                position: relative;
                max-width: 50%;
                padding-left: 52px;

                img {
                    width: 43px;
                    height: 43px;
                    border-radius: 50%;
                    border: 1px solid #dde4ff;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                h6 {
                    margin-bottom: 0;
                    color: #8891b5;
                    font: {
                        size: $font-size;
                        weight: 500;
                    }
                }
            }
            .details-btn {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                text-align: end;
                max-width: 50%;

                a {
                    display: inline-block;
                    width: 43px;
                    height: 43px;
                    position: relative;
                    border-radius: 50%;
                    background-color: #ebefff;
                    color: $black-color;
                    text-align: center;
                    font-size: 25px;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
            }
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    transform: scale(1.2) rotate(5deg);
                }
            }
        }
    }
}
.blog-notes {
    margin-top: 10px;
    text-align: center;

    p {
        line-height: initial;

        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
}
.single-blog-post-item {
    margin-bottom: 30px;
    position: relative;
    background-color: #fffaf3;

    .post-image {
        border-radius: 5px 5px 0 0;

        a {
            border-radius: 5px 5px 0 0;
            overflow: hidden;

            img {
                border-radius: 5px 5px 0 0;
                transition: $transition;
                width: 100%;
            }
        }
    }
    .post-content {
        padding: 20px;

        .category {
            display: inline-block;
            margin-bottom: 10px;
            color: $paragraph-color;
            text-transform: uppercase;
            font: {
                weight: 500;
                size: 14px;
            };
            &:hover {
                color: $optional-color;
            }
        }
        h3 {
            margin-bottom: 12px;
            line-height: 1.4;
            font: {
                size: 22px;
                weight: 500;
            };
            a {
                display: inline-block;
            }
        }
        .post-content-footer {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: 0;
                left: -7px;
                right: -7px;
                top: 18px;
            };
            li {
                color: $paragraph-color;
                font-size: 15px;
                position: relative;
                padding: {
                    left: 28px;
                    right: 7px;
                };
                .post-author {
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                    span {
                        color: $optional-color;
                    }
                }
                i {
                    color: $main-color;
                    position: absolute;
                    left: 7px;
                    top: 3px;
                }
                &:first-child {
                    padding-left: 7px;
                }
            }
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    transform: scale(1.07);
                }
            }
        }
    }
}
.blog-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: -105px;

            [class*=owl-] {
                color: $optional-color;
                font-size: 35px;
                margin: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                padding: 3px 0 0 0 !important;
                background: #fcf8f4;
                border-radius: 50%;
                transition: $transition;

                &.owl-next {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 25px;

                    a {
                        display: inline-block;
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $optional-color;
                        margin-right: 2px;
                        position: relative;
                        top: 1px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            };
            font: {
                size: 22px;
                weight: 500;
            };
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            };
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 28px;
                color: $paragraph-color;

                i {
                    color: $main-color;
                    display: inline-block;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        margin: {
            top: 30px;
        };
        .article-tags {
            a {
                display: inline-block;
                color: $white-color;
                background-color: $optional-color;
                padding: 4px 12px;
                border-radius: 5px;
                margin-right: 3px;
                font-size: 14px;

                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
    }
}
.comments-area {
    margin-top: 25px;

    .comments-title {
        margin: {
            bottom: 30px;
        };
        line-height: initial;
        font: {
            size: 22px;
            weight: 500;
        };
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 6px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                position: relative;
                z-index: 1;
                font: {
                    size: 13px;
                    weight: 600;
                };
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 16px;
        margin-bottom: 0.2em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 500;
            };
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        };
        a {
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 500;
            };
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            };
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 500;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 5px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 11px 25px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                transition: $transition;
                border-radius: 5px;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $optional-color;
                }
            }
        }
    }
}
.cointips-post-navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        top: 30px;
    };
    padding: {
        top: 30px;
        bottom: 30px;
    };
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    };
}
.prev-link-wrapper {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    };
    a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            };
        }
    }
    .prev-link-info-wrapper {
        color: $black-color;
        transition: $transition;
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 500;
            size: 17px;
        };
    }
    .meta-wrapper {
        display: block;
        color: $paragraph-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 400;
            size: 14px;
        };
    }
}
.next-link-wrapper {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: end;
    padding: {
        left: 15px;
    };
    a {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            };
        }
    }
    .next-link-info-wrapper {
        transition: $transition;
        color: $black-color;
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 500;
            size: 17px;
        };
    }
    .meta-wrapper {
        display: block;
        color: $paragraph-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 400;
            size: 14px;
        };
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    };
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 22px !important;
        };
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        content: "\ed67";
        font: {
            family: "boxicons" !important;
            weight: normal;
            style: normal;
            variant: normal;
            size: 135px;
        };
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        };
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    background-color: $optional-color;
    position: relative;
    z-index: 1;
    padding: {
        top: 100px;
        bottom: 90px;
    };
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .6;
        height: 100%;
        z-index: -1;
        animation: animatedBackground 15s linear infinite;
        background: {
            image: url(assets/img/bg_lines.svg);
            repeat: repeat;
            position: center center;
            size: cover;
        };
    }

    .page-title-breadcrumb {
        position: absolute;
        bottom: 0;
    }
}
.page-title-content {
    text-align: center;

    h2 {
        color: $white-color;
        margin-bottom: 0;
        font: {
            size: 40px;
            weight: 600;
        };
    }
    p {
        color: $white-color;
        font-size: 20px;
        margin: {
            top: 15px;
            bottom: 0;
        };
    }
}
@keyframes animatedBackground {
	0% {
        background-position: center center;
    }
	100% {
        background-position: 300px center;
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    height: 100vh;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font: {
            size: 40px;
            weight: 500;
        };
        margin: {
            top: 45px;
            bottom: 15px;
        };
    }
    p {
        max-width: 520px;
        margin: 0 auto 25px;
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 35px;
    text-align: center;

    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 48px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            weight: 500;
        };
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area {
    .widget {
        // margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            position: relative;
            font: {
                weight: 500;
                size: 21px;
            };
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;
                font-size: 20px;

                i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    left: 0;
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_cointips_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog-image/blog7.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog-image/blog8.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog-image/blog9.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/services-image/01eth.jpg);
                    }
                    &.bg5 {
                        background-image: url(assets/img/services-image/03eth.jpg);
                    }
                    &.bg6 {
                        background-image: url(assets/img/services-image/05eth.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 3px;
                        bottom: 5px;
                    };
                    font: {
                        size: 12px;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: $black-color;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                font: {
                    weight: 500;
                    size: $font-size;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 500;
                size: 14px !important;
            };
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
}

/*================================================
Login Area CSS
=================================================*/
.login-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/login-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.login-content {
    height: 100vh;
    min-height: fit-content;

    .login-form {
        text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        };
        .logo {
            margin-bottom: 35px;

            a {
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 500;
            };
        }
        p {
            margin: {
                top: 7px;
                bottom: 0;
            };
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: $white-color;
                color: $black-color;
                border: none;
                transition: $transition;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
                height: 55px;
                font: {
                    size: 14px;
                };
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .default-btn {
                display: block;
                width: 100%;
                box-shadow: 0px 5px 28.5px 1.5px rgba(255, 97, 47, 0.2);
                padding: {
                    left: 25px;
                };
            }
            .forgot-password {
                text-align: end;
                margin-top: 15px;

                a {
                    display: inline-block;
                    color: $main-color;
                    text-decoration: underline;

                    &:hover {
                        color: $optional-color;
                    }
                }
            }
            .connect-with-social {
                margin-top: 15px;
                margin-bottom: 15px;

                button {
                    display: block;
                    width: 100%;
                    position: relative;
                    border: 1px solid $main-color;
                    background-color: transparent;
                    transition: $transition;
                    padding: 11px 30px;
                    border-radius: 2px;
                    color: $main-color;
                    font: {
                        weight: 500;
                    }
                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 15px;
                        font-size: 20px;
                    }
                    &.facebook {
                        border-color: #3b5998;
                        color: #3b5998;

                        &:hover {
                            background-color: #3b5998;
                            color: $white-color;
                            border-color: #3b5998;
                        }
                    }
                    &.google {
                        margin-top: 10px;
                        border-color: #EA4335;
                        color: #EA4335;

                        &:hover {
                            background-color: #EA4335;
                            color: $white-color;
                            border-color: #EA4335;
                        }
                    }
                    &.walletconnect {
                        margin-top: 10px;
                        border-color: #4DB3FF;
                        color: #00A4FF;

                        &:hover {
                            background-color: #4DB3FF;
                            color: $white-color;
                            border-color: #00A4FF;
                        }
                    }
                    &.metamask {
                        margin-top: 10px;
                        border-color: #CC6228;
                        color: #CC6228;

                        &:hover {
                            background-color: #CC6228;
                            color: $white-color;
                            border-color: #CC6228;
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Signup Area CSS
=================================================*/
.signup-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/signup-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.signup-content {
    height: 100vh;
    min-height: fit-content;

    .signup-form {
        text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        };
        .logo {
            margin-bottom: 40px;

            a {
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 500;
            };
        }
        p {
            margin: {
                top: 7px;
                bottom: 0;
            };
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: $white-color;
                color: $black-color;
                border: none;
                transition: $transition;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
                height: 55px;
                font: {
                    size: 14px;
                };
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .default-btn {
                display: block;
                width: 100%;
                box-shadow: 0px 5px 28.5px 1.5px rgba(255, 97, 47, 0.2);
                padding: {
                    left: 25px;
                };
            }
            .connect-with-social {
                margin-top: 20px;

                span {
                    display: block;
                    text-transform: uppercase;
                    color: $paragraph-color;
                    margin-bottom: 20px;
                }
                button {
                    display: block;
                    width: 100%;
                    position: relative;
                    border: 1px solid $main-color;
                    background-color: transparent;
                    transition: $transition;
                    padding: 11px 30px;
                    border-radius: 2px;
                    color: $main-color;
                    font: {
                        weight: 500;
                    }
                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 15px;
                        font-size: 20px;
                    }
                    &.facebook {
                        border-color: #3b5998;
                        color: #3b5998;

                        &:hover {
                            background-color: #3b5998;
                            color: $white-color;
                            border-color: #3b5998;
                        }
                    }
                    &.google {
                        margin-top: 10px;
                        border-color: #EA4335;
                        color: #EA4335;

                        &:hover {
                            background-color: #EA4335;
                            color: $white-color;
                            border-color: #EA4335;
                        }
                    }
                    &.walletconnect {
                        margin-top: 10px;
                        border-color: #4DB3FF;
                        color: #00A4FF;

                        &:hover {
                            background-color: #4DB3FF;
                            color: $white-color;
                            border-color: #00A4FF;
                        }
                    }
                    &.metamask {
                        margin-top: 10px;
                        border-color: #CC6228;
                        color: #CC6228;

                        &:hover {
                            background-color: #CC6228;
                            color: $white-color;
                            border-color: #CC6228;
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.subscribe-area {
    &.bg-f4f5fe {
        background-color: $white-color;
        position: relative;
        z-index: 1;

        &::before {
            width: 100%;
            height: 50%;
            background-color: #f4f5fe;
            bottom: 0;
            left: 0;
            z-index: -1;
            content: '';
            position: absolute;
        }
    }
}
.subscribe-content {
    position: relative;
    z-index: 1;
    border-radius: 30px;
    text-align: center;
    padding: 70px;
    background-color: $optional-color;

    h2 {
        color: $white-color;
        max-width: 610px;
        margin: {
            bottom: 0;
            left: auto;
            right: auto;
        };
        font: {
            size: 40px;
            weight: 500;
        };
    }
    form {
        max-width: 600px;
        margin: {
            top: 40px;
            left: auto;
            right: auto;
        };
        .row {
            margin: {
                left: -7px;
                right: -7px;
            };
            .col-lg-8, .col-lg-4, .col-lg-12 {
                padding: {
                    left: 7px;
                    right: 7px;
                };
            }
        }
        #validator-newsletter {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -30px;
            color: $white-color;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            border: none;
            height: 50px;
            border-radius: 5px;
            color: $white-color;
            background-color: #c32f00;
            padding: 0 0 0 15px;
            font: {
                size: 14px;
                weight: 500;
            };
            &::placeholder {
                color: $white-color;
                transition: $transition;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        button {
            background-color: $black-color;
            color: $white-color;
            transition: $transition;
            border: none;
            border-radius: 5px;
            height: 50px;
            padding: 0 0;
            line-height: 50px;
            display: block;
            width: 100%;
            font: {
                size: $font-size;
                weight: 500;
            };
            i {
                margin-right: 2px;
                color: $optional-color;
                font-size: 20px;
                transition: $transition;
                position: relative;
                top: 4px;
            }
            &:hover, &:focus {
                background-color: $main-color;

                i {
                    color: $black-color;
                }
            }
        }
    }
}
.shape14 {
    position: absolute;
    left: 5%;
    top: 0;
    z-index: -1;

    img {
        animation: moveLeftBounce 5s linear infinite;
    }
}
.shape15 {
    position: absolute;
    right: 2%;
    top: 4%;
    z-index: -1;
}
.shape16 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.shape17 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.shape18 {
    position: absolute;
    z-index: -1;
    right: 20%;
    top: 32%;
}

/*================================================
Free Trial Area CSS
=================================================*/
.free-trial-area, .create-page-area, .my-page-area {
    position: relative;
    z-index: 1;
}
.my-page-actions {
    height: 70px;
    
    .default-btn {
        margin-top: 20px;
    }
}
.free-trial-content, .create-page-content, .my-page-content {
    text-align: center;
    max-width: 695px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        font: {
            size: 40px;
            weight: 500;
        };
    }
    p {
        margin-top: 20px;
    }
    .default-btn {
        margin-top: 20px;
    }
}
.shape10 {
    position: absolute;
    right: 30%;
    bottom: 25%;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 20s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape11 {
    position: absolute;
    left: 25%;
    bottom: 27%;
    z-index: -1;

    img {
        animation: moveBounce 5s linear infinite;
    }
}
.shape12 {
    position: absolute;
    left: 10%;
    bottom: 50%;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 20s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape13 {
    position: absolute;
    right: 18%;
    bottom: 60%;
    z-index: -1;

    img {
        animation: {
            name: rotateMe;
            duration: 20s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}

/*================================================
Contact Area CSS
=================================================*/
.ct-inner {
    background-color: $white-color;
    box-shadow: 0 10px 35px 5px rgba(137, 173, 255, 0.15);
    padding: 50px 30px;
}
.contact-features-list {
    padding-right: 15px;
    border-right: 1px solid #eeeeee;

    h3 {
        margin-bottom: 12px;
        font: {
            size: 22px;
            weight: 500;
        };
    }
    p {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            color: $paragraph-color;
            margin-bottom: 12px;
            position: relative;
            padding-left: 20px;

            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 4px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.ct-form {
    h3 {
        margin-bottom: 25px;
        font: {
            size: 32px;
            weight: 500;
        };
    }
    form {
        .row {
            margin: {
                left: -7px;
                right: -7px;
            };
            .col-lg-6, .col-lg-12 {
                padding: {
                    left: 7px;
                    right: 7px;
                };
            }
        }
        .form-group {
            margin-bottom: 15px;
        }
        .form-control {
            height: 48px;
            padding: 0 15px;
            line-height: initial;
            color: $black-color;
            background-color: transparent;
            border: 1px solid #cecfdf;
            border-radius: 5px;
            transition: $transition;
            box-shadow: unset !important;
            font: {
                size: 14px;
                weight: 400;
            };
            &:focus {
                border-color: $main-color;
            }
            &::placeholder {
                color: #999999;
            }
        }
        textarea.form-control {
            height: auto !important;
            padding-top: 15px;
            resize: vertical;
        }
        .default-btn {
            box-shadow: 0px 5px 28.5px 1.5px rgba(255, 97, 47, 0.3) !important;

            &:hover, &:focus {
                box-shadow: 0px 5px 28.5px 1.5px rgba(19, 196, 161, 0.3) !important;
            }
        }
    }
    .with-errors {
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 5px;
                bottom: 0;
            };
            li {
                color: red;
            }
        }
    }
    #msgSubmit {
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 500;
        };
        &.text-danger {
            margin-top: 5px;
        }
    }
}
.contact-info {
    text-align: center;
    // margin-top: 50px;

    .contact-info-content {
        h3 {
            margin-bottom: 15px;
            font: {
                size: 22px;
                weight: 500;
            };
        }
        h2 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 500;
            };
            a {
                display: inline-block;
                color: $main-color;

                &:hover {
                    color: $optional-color;
                }
                &:not(:first-child) {
                    color: $optional-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                margin: {
                    top: 5px;
                    bottom: 2px;
                };
                font: {
                    size: 15px;
                    weight: 500;
                };
            }
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 0;
                // top: 25px;
            };
            li {
                display: inline-block;
                margin: 0 2px;

                a {
                    width: 40px;
                    display: block;
                    height: 40px;
                    border: 1px solid #dadada;
                    border-radius: 50%;
                    color: #aba5a5;
                    font-size: 20px;
                    position: relative;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        border-color: $main-color;
                        background-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: $black-color;
    position: relative;
    z-index: 1;
    padding-top: 80px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        animation: imageChange 1s linear 1s infinite;
        background: {
            image: url(assets/img/footer-shape1.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        animation: imageChangeTwo 1s linear 1s infinite;
        background: {
            image: url(assets/img/footer-shape2.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.divider {
    width: 100%;
    position: absolute;
    height: 100px;
    pointer-events: none;
    -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    -webkit-mask-size: 100% 101%;
    mask-size: 100% 101%;
    background: $black-color;
    top: -100px;
    left: 0;
    z-index: -1;
}
.single-footer-widget {
    margin-bottom: 30px;

    h3 {
        color: $white-color;
        margin-bottom: 29px;
        font: {
            weight: 500;
            size: 22px;
        };
    }
    .logo {
        margin-bottom: 15px;

        a {
            display: inline-block;
        }
    }
    p {
        color: #acc5db;
    }
    .services-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: #acc5db;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: #acc5db;

                &:hover {
                    color: $main-color;
                    padding-left: 5px;
                }
            }
        }
    }
    .support-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: #acc5db;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: #acc5db;

                &:hover {
                    color: $main-color;
                    padding-left: 5px;
                }
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: $white-color;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: #acc5db;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .social {
        padding-left: 0;
        text-align: center;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 15px;
        };
        li {
            display: inline-block;
            margin-right: 5px;

            a {
                display: block;
                width: 35px;
                height: 35px;
                line-height: 40px;
                //background-color: #4e6e8a;
                text-align: center;
                font-size: 25px;
                border-radius: 2px;

                &:hover {
                    color: $black-color;
                    background-color: $white-color;
                }
            }
        }
    }
}
.copyright-area {
    border-top: 1px solid #1c2250;
    text-align: center;
    margin-top: 50px;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        line-height: initial;
        color: #acc5db;

        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
}
@keyframes imageChange {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes imageChangeTwo {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
    bottom: -100px;
	right: 15px;
	color: $black-color;
	background-color: $white-color;
	z-index: 4;
	width: 45px;
	text-align: center;
	height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 40px;
    transition: .9s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 15px;
    }
    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 70%;
        transform: translateY(-70%);
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &:hover {
        background-color: $optional-color;
        color: $white-color;
        transition: $transition;
        transform: translateY(-10px);
    }
}

/*================================================
SaaS Home Page CSS
=================================================*/
.black-btn {
    background-color: $black-color !important;
    margin-left: 10px;

    span {
        background-color: $main-color !important;
    }
    i {
        color: $white-color;
    }
}
/* Saas banner */
.saas-banner {
    background: $optional-color;
    position: relative;
    z-index: 1;
    padding: {
        top: 150px;
        bottom: 220px;
    };
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .6;
        height: 100%;
        z-index: -1;
        animation: animatedBackground 15s linear infinite;
        background: {
            image: url(assets/img/bg_lines.svg);
            repeat: repeat;
            position: center center;
            size: cover;
        };
    }
    .saas-image {
        z-index: 1;

        &.mt-70 {
            margin-top: 70px;
        }
    }

    .hero-content {
        z-index: 1;
        position: relative;
        h1 {
            color: $white_color;
            font: {
                size: 52px;
                weight: 500;
            }
            margin: 0;
        }
        p {
            color: $white-color;
            font-size: 18px;
            margin: {
                top: 30px;
                bottom: 35px;
            }
        }
        .default-btn {
            background-color: #080a3c;

            i {
                color: $optional-color;
            }
            &:hover {
                i {
                    color: $white-color;
                }
            }
            // span {
            //     background-color:$optional-color;
            // }
        }

        .video-btn {
            margin-left: 15px;
            color: $white_color;
            position: relative;
            display: inline-block;
            margin-top: 6px;
            //transform: .6s;

            font: {
                size: 15px;
                weight: 600;
            }
            i {
                font-size: 20px;
                position: relative;
                margin-left: inherit;
                background: $white-color;
                color: $optional-color;
                height: 35px;
                width: 35px;
                display: inline-block;
                text-align: center;
                line-height: 35px;
                border-radius: 50%;
                padding-left: 3px;
                margin-right: 6px;
            }
            &:hover {
                i {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .saas-image {
        position: relative;
        img {
            position: absolute;

            &:nth-child(1) {
                top: -200px;
                left: 0;
            }
            &:nth-child(2) {
                left: 55px;
                top: -100px;
            }
            &:nth-child(3) {
                left: 32%;
                z-index: 1;
                top: -170px;
            }
            &:nth-child(4) {
                right: 27%;
                top: 110px;
                z-index: 2;
            }
            &:nth-child(5) {
                right: 15%;
                top: 80px;
                z-index: 2;
            }
            &:nth-child(6) {
                right: 36%;
                top: -170px;
            }
            &:nth-child(7) {
                right: 50%;
                top: 96px;
                z-index: 2;
            }
            &:nth-child(8) {
                right: 25%;
                top: 45px;
                z-index: 1;
            }
            &:nth-child(9) {
                right: 100px;
                top: 5px;
            }
            &:nth-child(10) {
                left: 28%;
                top: -76px;
                z-index: 1;
            }
            &:nth-child(11) {
                right: 40%;
                top: -20px;
            }
            &:nth-child(12) {
                top: -292px;
                left: 45px;
            }
            &:last-child {
                display: none;
            }
        }
    }
}

#particles-js {
    width: 100%;
    height: 100%;
}
/* End Saas banner */

/* Shape rotate */
.shape-rotate {
    position: absolute;
    left: 13%;
    top: 23%;
    z-index: -1;
    opacity: .3;
}
.rotateme {
    animation-name: rotateme;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes rotateme {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-o-keyframes rotateme {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes animationFramesOne{
    0% {
        transform:  translate(0px,0px)  rotate(0deg) ;
    }
    20% {
        transform:  translate(73px,-1px)  rotate(36deg) ;
    }
    40% {
        transform:  translate(141px,72px)  rotate(72deg) ;
    }
    60% {
        transform:  translate(83px,122px)  rotate(108deg) ;
    }
    80% {
        transform:  translate(-40px,72px)  rotate(144deg) ;
    }
    100% {
        transform:  translate(0px,0px)  rotate(0deg) ;
    }
}
@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
    }
    20% {
        -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
    }
    40% {
        -webkit-transform:  translate(141px,72px)  rotate(72deg) ;
    }
    60% {
        -webkit-transform:  translate(83px,122px)  rotate(108deg) ;
    }
    80% {
        -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
    }
    100% {
        -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
    }
}
@-webkit-keyframes ripple-white {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
    }
    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
    }
}
@keyframes ripple-white {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
    }
    100% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
    }
}
/* End Shape rotate */

/* Features inner content */
.features-inner-content {
    .features-item {
        position: relative;
        padding-left: 100px;
        margin-bottom: 30px;
        text-align: start;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            width: 70px;
            height: 70px;
            line-height: 70px;
            font-size: 30px;
            text-align: center;
            border-radius: 100%;
            color: $white-color;
            position: absolute;
            top: 0;
            left: 0;
            transition: .6s;
        }
        &:hover {
            i {
                border-radius: 50% !important;
            }
        }
        h3 {
            font-size: 22px;
        }
        p {
            margin: 0;
        }
    }
}
.fun-facts-inner-content {
    h2 {
        font-weight: 600;
    }
    p {
        margin-bottom: 15px !important;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            margin-bottom: 6px;
            color: #4a6f8a;
            i {
                color: $main-color;
                padding-right: 3px;
            }
        }
    }
    .default-btn {
        margin-top: 20px !important;
    }
}
/* End Features inner content */

/* Overview section */
.overview-item {
    margin-bottom: 50px;
    border-bottom: 1px solid #edf0f7;
    padding-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}
.overview-content {
    .number {
        background-color: $main-color;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        border-radius: 100%;
        color: $white-color;
        margin-bottom: 20px;
        display: inline-block;
        font-weight: 600;
    }
    h3 {
        font-size: 25px;
    }
    p {
        margin: 0;
    }

    ul {
        margin: 15px 0 0;
        padding: 0;
        li {
            list-style-type: none;
            margin-bottom: 6px;
            color: $paragraph-color;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                padding-right: 3px;
            }
        }
    }

    .default-btn {
        margin-top: 25px;
    }
}
/* End Overview section */

/* Service details */
.service-details-image {
    margin-bottom: 30px;

    img {
        width: 100%;
        border-radius: 5px;
    }
}
.service-details-content {
    margin-top: 5px;
    h1 {
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h2 {
        font-size: 28px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h3 {
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h4 {
        font-size: 21px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h5 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h6 {
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    ul, ol {
        padding-left: 17px;
        li {
            line-height: 1.7;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .service-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 30px;
        background: #f4f5fe;
        padding: 30px 20px;
        border-radius: 10px;



        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font: {
                    size: 18px;
                    weight: 600;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    a {
                        background-color: $white-color;
                        color: $paragraph-color;
                        display: inline-block;

                        width: 30px;
                        height: 30px;
                        text-align: center;
                        border-radius: 30px;
                        line-height: 32px;

                        &:hover {
                            color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
            .default-btn {
                display: inline-block;
                padding: 12px 30px;
                color: $white-color;
                text-transform: capitalize;
                background-color: $main-color;
                border: 1px solid $main-color;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                border-radius: 30px;
                font-size: 14px;
                font-weight: 500;
                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }

            &:last-child {
                text-align: center;
            }
        }
    }
}
/* End Service details */

/*================================================
Digital Agency Banner Area CSS
=================================================*/
.digital-agency-banner {
    padding: {
        top: 235px;
        bottom: 235px;
    };
    background: {
        image: url(assets/img/marketing-agency/banner-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
}
.digital-agency-banner-content {
    max-width: 540px;

    h1 {
        color: $white-color;
        margin-bottom: 30px;
        line-height: 1.3;
        font: {
            size: 57px;
            weight: 700;
        };
        span {
            color: $main-color;
        }
    }
    p {
        color: $white-color;
    }
    .default-btn {
        margin-top: 18px;
    }
}

/*================================================
Featured Area CSS
=================================================*/
.single-featured-box {
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    img {
        border-radius: 5px;
        transition: $transition;
    }
    h3 {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        margin-bottom: 0;
        color: $white-color;
        line-height: 1.5;
        z-index: 2;
        padding: {
            left: 20px;
            right: 20px;
            top: 25px;
        };
        font: {
            size: 24px;
            weight: 500;
        };
    }
    .read-more-btn {
        position: absolute;
        left: 20px;
        bottom: 25px;
        z-index: 2;
        font-weight: 600;
        color: $white-color;
        padding-right: 22px;
        display: inline-block;

        i {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 21px;
            color: $main-color;
            transition: $transition;
        }
    }
    .link-btn {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        z-index: 1;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: #000000;
        opacity: .55;
    }
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

/*================================================
Services Area CSS
=================================================*/
.single-services-box {
    margin-bottom: 30px;
    overflow: hidden;
    background-color: #f9f9f9;

    .content {
        padding: 30px 0 30px 25px;

        h3 {
            line-height: 1.4;
            font: {
                weight: 500;
                size: 22px;
            };
            margin: {
                bottom: 10px;
                right: -20px;
            };
        }
        .read-more-btn {
            font-weight: 500;
            padding-right: 22px;
            position: relative;
            display: inline-block;

            i {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 21px;
                color: $main-color;
                transition: $transition;
            }
            &:hover {
                padding-right: 28px;
            }
        }
    }
    .image {
        height: 100%;
        -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0% 100%);
        background: {
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &.bg-1 {
            background-image: url(assets/img/marketing-agency/services-img1.jpg);
        }
        &.bg-2 {
            background-image: url(assets/img/marketing-agency/services-img2.jpg);
        }
        &.bg-3 {
            background-image: url(assets/img/marketing-agency/services-img3.jpg);
        }
        &.bg-4 {
            background-image: url(assets/img/marketing-agency/services-img4.jpg);
        }
        img {
            display: none;
        }
    }
}
.services-btn-box {
    text-align: center;
    margin-top: 10px;

    .default-btn {
        background-color: transparent;
        border: 1px solid $optional-color;
        color: $optional-color;
        padding: 11.5px 35px 11px 65px;

        i {
            left: 35px;
            color: $optional-color;
        }
        &:hover {
            border-color: $main-color;
            color: $white-color;

            i {
                color: $white-color;
            }
        }
    }
}

/*================================================
Case Studies Area CSS
=================================================*/
.case-studies-area {
    background-color: #00062f;
    overflow: hidden;

    .section-title {
        h2 {
            color: $white-color;
        }
    }
    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
}
.single-case-studies-item {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px;

    .image {
        border-radius: 5px 5px 0 0;

        img {
            border-radius: 5px 5px 0 0;
        }
    }
    .content {
        background-color: #f9f9f9;
        text-align: start;
        padding: 30px 25px 25px;
        border-radius: 0 0 5px 5px;
        position: relative;

        h3 {
            margin-bottom: 0;
            line-height: 1.4;
            font: {
                weight: 500;
                size: 22px;
            };
        }
        .link-btn {
            width: 40px;
            height: 40px;
            line-height: 48px;
            text-align: center;
            background-color: $optional-color;
            transition: $transition;
            color: $white-color;
            border-radius: 3px;
            position: absolute;
            font-size: 30px;
            right: 20px;
            top: -20px;
            display: inline-block;

            &:hover {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
}
.case-studies-slides {
    &.owl-theme {
        left: calc((100% - 1270px) / 2);
        position: relative;

        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 26.88%;
            top: -105px;

            [class*=owl-] {
                color: $optional-color;
                font-size: 35px;
                margin: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                padding: 3px 0 0 0 !important;
                background: $white-color;
                border-radius: 50%;
                transition: $transition;

                &.owl-next {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
Company Preview Area CSS
=================================================*/
.company-preview-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/marketing-agency/company-preview.jpg);
        size: cover;
        repeat: no-repeat;
        position: center center;
    };
    padding: {
        top: 265px;
        bottom: 265px;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        bottom: 0;
        background-color: #000000;
        opacity: 0.4;
    }
}
.company-preview-video {
    text-align: center;

    .video-btn {
        display: inline-block;
        width: 120px;
        height: 120px;
        background-color: rgba(255, 255, 255, .79);
        border-radius: 50%;
        position: relative;
        color: $optional-color;
        font-size: 100px;
        z-index: 1;
        margin: {
            left: auto;
            right: auto;
        };
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            transition: $transition;
        }
        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            z-index: 1;
            transform: translateY(-50%);
            margin: {
                left: auto;
                right: auto;
            };
        }
        &::before {
            animation: ripple 2s linear infinite
        }
        &::after {
            animation: ripple 2s linear 1s infinite
        }
        &:hover, &:focus {
            background-color: $main-color;
            color: $white-color;

            &::after, &::before {
                border-color: $main-color;
            }
        }
    }
}

/*================================================
Let's Talk Area CSS
=================================================*/
.lets-talk-content {
    .sub-title {
        font-weight: 600;
        color: $optional-color;
        display: block;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 10px;
        font: {
            size: 40px;
            weight: 500;
        };
    }
}
.lets-talk-btn {
    text-align: end;
}

// seo-marketing-home-with-full-background CSS
.seo-marketing-home-with-full-background {
    background-image: url(assets/img/seo-marketing/full-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

// section-title-with-large-box CSS
.section-title-with-large-box {
    max-width: 750px;
    margin: 0 auto 45px;
    text-align: center;

    span {
        font-weight: 400;
        color: $main-color;
        margin-bottom: 15px;
        display: inline-block;
        font-size: $font-size;
    }
    h2 {
        font-size: 38px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.4;
    }
}

/*================================================
Top Bar Area CSS
=================================================*/
.top-bar-area {
    background-color: $black-color;
    padding: {
        top: 15px;
        bottom: 15px;
    };
    .container-fluid {
        padding: {
            left: 35px;
            right: 35px;
        };
    }
}
.top-bar-info {
    padding: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
        display: inline-block;
        color: $white-color;
        margin-right: 20px;

        span {
            color: $main-color;
            font-weight: 500;
        }
        a {
            color: $white-color;

            &:hover {
                color: $optional-color;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.top-bar-social {
    padding: 0;
    display: flex;
    text-align: end;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-end;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 10px;
        line-height: 1;

        &:last-child {
            margin-right: 0;
        }
        a {
            display: inline-flex;
            line-height: 1;

            i {
                font-size: 20px;
                color: $white-color;
                transition: $transition;

                &:hover {
                    color: $optional-color;
                }
            }
        }
        span {
            color: $main-color;
        }
    }
}

/*================================================
SEO Marketing Banner Area CSS
=================================================*/
.seo-marketing-banner {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: {
        top: 100px;
        bottom: 100px;
    };
    .container-fluid {
        padding: {
            left: 35px;
            right: 35px;
        };
    }
}
.seo-marketing-banner-content {
    span {
        font-weight: 500;
        margin-bottom: 15px;
        color: $black-color;
        display: inline-block;
    }
    h1 {
        font-size: 80px;
        font-weight: bold;
        margin-bottom: 12px;
    }
    p {
        max-width: 650px;
        margin-bottom: 0;
    }
    .banner-btn {
        padding: 0;
        display: flex;
        align-items: center;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            display: inline-flex;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .default-btn {
                background-color: $main-color;

                span {
                    background-color: $optional-color;
                }
            }
            .video-btn {
                display: inline-block;
                color: $black-color;
                position: relative;
                transition: .6s;
                font: {
                    size: 15px;
                    weight: 600;
                }
                i {
                    width: 45px;
                    height: 45px;
                    font-size: 20px;
                    line-height: 45px;
                    text-align: center;
                    border-radius: 50%;
                    margin-right: 10px;
                    position: relative;
                    color: $white-color;
                    margin-left: inherit;
                    display: inline-block;
                    background: #824CF3;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}
.seo-marketing-banner-shape-1 {
    top: 5%;
    left: 5%;
    z-index: -1;
    position: absolute;
    transform: translateY(-5%) translateX(-5%);

    img {
        animation-duration: 10s;
        animation-name: rotateMe;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.seo-marketing-banner-shape-2 {
    left: 35%;
    bottom: 20%;
    z-index: -1;
    position: absolute;
    animation: moveBounce 5s linear infinite;
    transform: translateY(-20%) translateX(-35%);
}
.seo-marketing-banner-shape-3 {
    top: 5%;
    right: 20%;
    z-index: -1;
    position: absolute;
    transform: translateY(-5%) translateX(-20%);

    img {
        animation-duration: 10s;
        animation-name: rotateMe;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
Features Area CSS
=================================================*/
.sm-features-card {
    background-color: $white-color;
    border: 1px solid #E5E5E5;
    transition: $transition;
    border-radius: 25px;
    margin-bottom: 30px;
    padding: 25px;

    img {
        margin-bottom: 22px;
    }
    h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);
    }
}

/*================================================
About Area CSS
=================================================*/
.sm-about-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.sm-about-content {
    padding-left: 30px;

    h3 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            font-size: 18px;
            font-weight: 500;
            padding-left: 35px;
            position: relative;
            margin-bottom: 30px;
            color: $black-color;
            list-style-type: none;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                left: 0;
                top: 50%;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                position: absolute;
                border-radius: 10px;
                color: $white-color;
                display: inline-block;
                transition: $transition;
                transform: translateY(-50%);
                background-color: $main-color;
            }
            &:hover {
                i {
                    background-color: $optional-color;
                }
            }
        }
    }
    .about-btn {
        margin-top: 35px;
    }
}
.sm-about-shape-1 {
    top: 5%;
    left: 5%;
    z-index: -1;
    position: absolute;
    transform: translateY(-5%) translateX(-5%);

    img {
        animation-duration: 10s;
        animation-name: rotateMe;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}
.sm-about-shape-2 {
    top: 5%;
    right: 5%;
    z-index: -1;
    position: absolute;
    transform: translateY(-5%) translateX(-5%);

    img {
        animation-duration: 10s;
        animation-name: rotateMe;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

/*================================================
SM Websites Area CSS
=================================================*/
.sm-websites-inner-area {
    background-color: $black-color;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: {
        left: 70px;
        right: 70px;
    };
    .websites-dot-shape {
        transform: translateY(-50%);
        position: absolute;
        z-index: -1;
        right: 0;
        top: 50%;
    }
}
.sm-websites-content {
    padding-right: 30px;

    h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 18px;
        color: $white-color;
    }
    p {
        color: $white-color;
        margin-bottom: 0;
    }
    form {
        margin-top: 25px;

        .form-group {
            margin-bottom: 25px;

            label {
                display: inline-block;
                font-size: $font-size;
                margin-bottom: 10px;
                color: $white-color;
                font-weight: 500;
            }
            .form-control {
                outline: 0;
                width: 100%;
                height: 60px;
                font-size: 15px;
                box-shadow: unset;
                padding: 15px 20px;
                border-radius: 8px;
                color: $white-color;
                display: inline-block;
                transition: $transition;
                background-color: transparent;
                border: 1px solid $white-color;

                &::placeholder {
                    color: $white-color;
                    transition: $transition;
                }
                &:focus {
                    border: 1px solid $main-color;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
}

/*================================================
SM Services Area CSS
=================================================*/
.sm-services-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.sm-services-card {
    background-color: $white-color;
    border: 1px solid #E5E5E5;
    transition: $transition;
    border-radius: 25px;
    margin-bottom: 30px;
    padding: 25px;

    &.some-left {
        position: relative;
        left: -30px;
    }
    &.some-right {
        position: relative;
        right: -30px;
    }
    .content {
        position: relative;
        padding-left: 100px;

        .icon {
            position: absolute;
            left: 0;
            top: 0;

            i {
                width: 80px;
                height: 80px;
                font-size: 42px;
                line-height: 80px;
                text-align: center;
                color: $white-color;
                border-radius: 50px;
                display: inline-block;
                transition: $transition;
                background-color: $main-color;
            }
        }
        h3 {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 15px;

            a {
                color: $black-color;
            }
        }
        p {
            margin-bottom: 0;
            transition: $transition;
        }
    }
    &:hover {
        background-color: $main-color;
        border: 1px solid $main-color;
        transform: translateY(-5px);

        .content {
            .icon {
                i {
                    background-color: $white-color;
                    color: $black-color;
                }
            }
            h3 {
                a {
                    color: $white-color;
                }
            }
            p {
                color: $white-color;
            }
        }
    }
}
.sm-services-card-image {
    margin-bottom: 30px;
    padding: {
        left: 20px;
        right: 20px;
    };
}
.sm-services-shape {
    top: 5%;
    left: 5%;
    z-index: -1;
    position: absolute;
    transform: translateY(-5%) translateX(-5%);

    img {
        animation-duration: 10s;
        animation-name: rotateMe;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

/*================================================
SM Funfacts Area CSS
=================================================*/
.sm-funfacts-inner-box {
    background: linear-gradient(270deg, #93C8E0 0%, #87BADB 100%);
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: {
        left: 50px;
        right: 50px;
    };
    &.bg-with-linear-gradient {
        background: linear-gradient(90deg, #FF512F 0%, #DD2476 100%);

        .sm-single-funfacts {
            position: relative;

            i {
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;
                font-size: 50px;
                line-height: 80px;
                text-align: center;
                position: absolute;
                color: $black-color;
                border-radius: 45px;
                display: inline-block;
                transition: $transition;
                background-color: $white-color;
            }
            h3 {
                font-size: 35px;
                margin-bottom: 8px;

                .sign-icon {
                    position: relative;
                    font-size: 30px;
                    left: -5px;
                    top: -1px;
                }
            }
            p {
                color: $white-color;
            }
            &:hover {
                i {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .funfacts-shape {
        position: absolute;
        opacity: 10%;
        z-index: -1;
        right: 10px;
        top: 10px;
    }
}
.sm-single-funfacts {
    position: relative;
    margin-bottom: 30px;
    padding-left: 100px;

    img {
        left: 0;
        top: 50%;
        max-width: 80px;
        position: absolute;
        transform: translateY(-50%);
    }
    h3 {
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 5px;
        color: $white-color;

        .sign-icon {
            position: relative;
            font-size: 45px;
            left: -5px;
        }
    }
    p {
        color: $black-color;
        font-size: 16.5px;
        font-weight: 500;
    }
}
.me-funfacts {
    position: relative;
    z-index: 1;

    &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        z-index: -1;
        height: 120px;
        position: absolute;
        background-color: $black-color;
    }
}

/*================================================
SM Work Area CSS
=================================================*/
.sm-work-content {
    h3 {
        font-size: 35px;
        font-weight: 600;
        line-height: 1.4;
    }
    .sm-work-inner-card {
        margin-top: 30px;
        position: relative;
        padding-left: 65px;

        .number {
            position: absolute;
            top: 0;
            left: 0;

            span {
                background: linear-gradient(90deg, #13C4A1 10.98%, #824CF3 87.38%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-weight: bold;
                font-size: 70px;
                line-height: 1;
                opacity: 0.1;
            }
        }
        h4 {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.sm-work-image {
    text-align: center;
}

/*================================================
SM FAQ Area CSS
=================================================*/
.sm-faq-area {
    position: relative;
    overflow: hidden;
    z-index: 1;

    .container {
        border-top: 1px solid #E5E5E5;
    }
}
.sm-faq-accordion {
    padding-left: 30px;

    h2 {
        margin-bottom: 40px;
        font: {
            size: 35px;
            weight: 600;
        };
    }
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
            bottom: 0;
        };
        .accordion-item {
            background: #f5f5ff;
            margin-bottom: 10px;
            border-radius: 5px;
            display: block;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 12px 40px 12px 25px;
            color: $black-color;
            position: relative;
            border-radius: 5px;
            display: block;
            font: {
                size: 16px;
                weight: 500;
            };
            i {
                transform: translateY(-50%);
                position: absolute;
                right: 20px;
                top: 50%;
                color: $black-color;
                font-size: 20px;
                transition: $transition;
            }
            &.active {
                i {
                    &::before {
                        content: "\eb8b";
                        color: $main-color;
                    }
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            padding: 15px 25px;
            font-size: 14px;
            border-top: 1px solid $main-color;
            color: $paragraph-color;

            &.show {
                display: block;
            }
        }
    }
}
.sm-faq-shape {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
SM Partner Area CSS
=================================================*/
.sm-partner-inner-box {
    background: linear-gradient(270deg, #EC98A2 0%, #FC96BB 99.29%);
    border-radius: 15px;
    padding: {
        left: 50px;
        right: 50px;
    };
    position: relative;
    z-index: 1;
    .section-title-with-large-box {
        span {
            color: $white-color;
            margin-bottom: 0;
            font-size: 25px;
            font-weight: 600;
        }
    }
}
.sm-partner-box {
    text-align: center;
    margin-bottom: 30px;
}
.sm-partner-shape {
    position: absolute;
    right: -20px;
    bottom: -20px;
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.is-partner {
    background: #FEF9F9;

    .section-title-with-large-box {
        span {
            color: $black-color;
            margin-bottom: 0;
            font-size: 25px;
            font-weight: 600;
        }
    }
}
.me-partner {
    background-color: #F4F6FC;

    .section-title-with-large-box {
        span {
            color: $black-color;
            margin-bottom: 0;
            font-size: 25px;
            font-weight: 600;
        }
    }
}

/*================================================
SM Overview Area CSS
=================================================*/
.sm-overview-card {
    text-align: center;
    margin-bottom: 30px;
    transition: $transition;

    .image-icon {
        display: inline-block;
        height: 150px;
        width: 150px;
        line-height: 150px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #E5E5E5;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
    }
    &:hover {
        transform: translateY(-5px);
    }
}

/*================================================
SM Pricing Area CSS
=================================================*/
.sm-pricing-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.sm-pricing-card {
    background-color: $white-color;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 35px 30px;
    margin-bottom: 30px;
    transition: $transition;

    .pricing-header {
        margin-bottom: 25px;

        h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 0;
            transition: $transition;
        }
    }
    .price {
        font-size: 70px;
        font-weight: 600;
        color: $black-color;
        line-height: 1;
        transition: $transition;

        span {
            font-size: 15px;
            font-weight: 400;
            color: $paragraph-color;
            position: relative;
            top: -8px;
            transition: $transition;
        }
    }
    .pricing-features {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            color: $paragraph-color;
            font-weight: 400;
            margin-bottom: 15px;
            position: relative;
            padding-left: 25px;
            font-size: 16.5px;
            transition: $transition;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .price-btn {
        margin-top: 25px;

        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
    &:hover {
        background-color: $black-color;
        border: 1px solid $black-color;
        transform: translateY(-5px);

        .pricing-header {
            h3 {
                color: $main-color;
            }
        }
        .price {
            color: $white-color;

            span {
                color: $white-color;
            }
        }
        .pricing-features {
            li {
                color: $white-color;
            }
        }
    }
    &.with-black-color {
        background-color: $black-color;
        border: 1px solid $black-color;

        .pricing-header {
            h3 {
                color: $white-color;
            }
        }
        .price {
            color: $white-color;

            span {
                color: $white-color;
            }
        }
        .pricing-features {
            li {
                color: $white-color;

                i {
                    color: $white-color;
                }
            }
        }
        &:hover {
            background-color: $main-color;
            border: 1px solid $main-color;

            .default-btn {
                background-color: $white-color;
                color: $black-color;
            }
        }
    }
}
.sm-pricing-shape {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.sm-pricing-section-content {
    max-width: 475px;

    span {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    p {
        margin-bottom: 20px;
    }
    .default-btn {
        background-color: $main-color;
        padding: 15px 30px;

        span {
            background-color: $optional-color;
        }
    }
}
.me-pricing-shape1 {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-pricing-shape2 {
    position: absolute;
    bottom: 5%;
    right: 2%;
    transform: translateY(-5%) translateX(-2%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
SM Subscribe Area CSS
=================================================*/
.sm-subscribe-area {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        background: $white-color;
        width: 100%;
        height: 100px;
        z-index: -1;
    }
    &.me-subscribe {
        &::before {
            background: #F4F6FC;
            height: 190px;
        }
    }
}
.sm-subscribe-inner-box {
    background: linear-gradient(270deg, #7B7CD6 0%, #A4A7E3 99.29%);
    border-radius: 15px;
    padding: {
        left: 50px;
        right: 50px;
    };
    position: relative;
    z-index: 1;
    overflow: hidden;
    .section-title-with-large-box {
        h2 {
            color: $white-color;
        }
    }
    .newsletter-form {
        position: relative;
        max-width: 590px;
        margin: auto;

        .form-group {
            .input-newsletter {
                display: inline-block;
                background-color: transparent;
                height: 60px;
                width: 100%;
                border: 1px solid $white-color;
                outline: 0;
                padding: 10px 25px;
                font-size: $font-family;
                font-weight: 500;
                color: $white-color;
                border-radius: 5px;

                &::placeholder {
                    color: $white-color;
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        button {
            display: inline-block;
            height: 60px;
            width: 60px;
            line-height: 60px;
            background-color: $main-color;
            color: $white-color;
            font-size: 30px;
            border: none;
            position: absolute;
            right: 0;
            top: 0;
            transition: $transition;
            border-radius: 5px;

            &:hover {
                background-color: $optional-color;
            }
        }
        .validation-danger {
            color: $white-color;
            font-size: 15px;
            margin-top: 10px;
        }
    }
    &.with-linear-gradient-color {
        background: linear-gradient(90deg, #FF512F 0%, #F09819 100%);
    }
}
.sm-subscribe-shape-1 {
    position: absolute;
    top: 15%;
    left: 2%;
    transform: translateY(-15%) translateX(-2%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.sm-subscribe-shape-2 {
    position: absolute;
    bottom: 15%;
    right: 2%;
    transform: translateY(-15%) translateX(-2%);
    z-index: -1;
    animation: moveLeftBounce 1s linear infinite;
}

/*================================================
SM Blog Area CSS
=================================================*/
.sm-blog-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.sm-blog-card {
    margin-bottom: 30px;
    transition: $transition;

    .blog-image {
        border-radius: 8px;
        overflow: hidden;

        a {
            img {
                transition: $transition;
                border-radius: 8px;
            }
        }
    }
    .blog-content {
        margin-top: 25px;

        .meta {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            li {
                color:$paragraph-color;
                position: relative;
                display: inline-block;
                margin-right: 30px;

                img {
                    max-width: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                a {
                    color: $paragraph-color;
                    display: flex;
                    align-items: center;
                    font-weight: 400;

                    &:hover {
                        color: $optional-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                    color: $paragraph-color;
                    padding-left: 25px;
                    top: 1.5px;
                }
                i {
                    color: $main-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 18px;
                }
            }
        }
        h3 {
            font-size: 25px;
            line-height: 1.4;
            margin-bottom: 0;
            font-weight: 600;

            a {
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    &:hover {
        .blog-image {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}
.sm-blog-shape {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
IT Solution Banner Slides Area CSS
=================================================*/
.is-banner-slides {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        background: #FEF9F9;
        width: 100%;
        height: 350px;
        z-index: -1;
    }
}
.is-banner-item {
    background-image: url(assets/img/it-solution/banner/banner-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: {
        top: 250px;
        bottom: 250px;
    };
    margin: {
        left: 20px;
        right: 20px;
    };
    border-radius: 10px;
}
.is-banner-content {
    span {
        color: $main-color;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h1 {
        font-size: 90px;
        font-weight: bold;
        margin-bottom: 12px;
        color: $white-color;
    }
    p {
        max-width: 650px;
        margin-bottom: 0;
        color: $white-color;
    }
    .banner-btn {
        margin-top: 25px;

        .default-btn {
            background-color: $main-color;

            span {
                background-color: $optional-color;
            }
        }
    }
}
.is-banner-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 50px;
            top: 45%;
            transform: translateY(-45%);

            [class*=owl-] {
                margin: 10px 0;
                padding: 0;
                display: block;
                width: 45px;
                height: 45px;
                line-height: 45px;
                background-color: $white-color;
                box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.06);
                color: $black-color;
                border-radius: 0;
                font-size: 25px;
                position: relative;
                border-radius: 50px;
                transition: $transition;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
IS Features Area CSS
=================================================*/
.is-features-area {
    background: #FEF9F9;
}
.is-features-card {
    background-color: $white-color;
    border: 1px solid #E5E5E5;
    border-radius: 25px;
    padding: 80px 35px 35px 35px;
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    .number {
        span {
            background: linear-gradient(90deg, #13C4A1 10.98%, #824CF3 87.38%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            opacity: 0.2;
            font-size: 70px;
            font-weight: bold;
            line-height: 1;
        }
    }
    h3 {
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 600;
    }
    p {
        margin-bottom: 0;
    }
    .icon {
        position: absolute;
        right: 30px;
        top: 30px;

        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
            transition: $transition;

            &:hover {
                background-color: $optional-color;
            }
        }
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);
    }
}

/*================================================
IS About Area CSS
=================================================*/
.is-about-area {
    overflow: hidden;
}
.is-about-content {
    padding-right: 30px;

    span {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .about-btn {
        margin-top: 25px;

        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
}
.is-about-image {
    position: relative;
    z-index: 1;

    img {
        border-radius: 15px;
    }
    .is-about-shape {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            animation-name: rotateMe;
            animation-duration: 30s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}

/*================================================
IS Services Area CSS
=================================================*/
.is-services-area {
    background-color: $black-color;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .section-title-with-large-box {
        h2 {
            color: $white-color;
        }
    }
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.is-services-card {
    background: #0C1E34;
    border-radius: 15px;
    padding: 30px;
    transition: $transition;

    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0;

        a {
            color: $white-color;
        }
    }
    .is-services-image {
        border-radius: 10px;
        overflow: hidden;
        margin: {
            top: 30px;
            bottom: 22px;
        };
        a {
            img {
                border-radius: 10px;
                transition: $transition;
            }
        }
    }
    p {
        color: $white-color;
        margin-bottom: 20px;
        transition: $transition;
    }
    .default-btn {
        background-color: $main-color;
        padding: 15px 30px;

        span {
            background-color: $optional-color;
        }
    }
    &:hover {
        background-color: $white-color;

        h3 {
            a {
                color: $black-color;
            }
        }
        p {
            color: $paragraph-color;
        }
        .is-services-image {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}
.is-services-slides {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    background: $white-color;
                    transition: $transition;
                    border-radius: 10px;

                }
                &.active, &:hover {
                    span {
                        background-color: $main-color;
                        width: 30px;
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 35px;
        }
    }
}
.me-services-shape1 {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-services-shape2 {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-services-shape3 {
    position: absolute;
    bottom: 5%;
    left: 25%;
    transform: translateY(-5%) translateX(-25%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
IS Expertise Area CSS
=================================================*/
.is-expertise-content {
    padding-left: 50px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    p {
        margin-bottom: 20px;
    }
    .expertise-funfacts {
        background: #EFE8E4;
        border-radius: 15px;
        padding: 30px;
        margin-top: 10px;

        h2 {
            font-size: 35px;
            color: $black-color;
            font-weight: bold;
            margin-bottom: 10px;
            line-height: 0;

            .sign-icon {
                font-size: 35px;
                position: relative;
                left: -2px;
                top: 1px;
                font-weight: 600;
            }
            .odometer-formatting-mark {
                display: none;
            }
        }
        p {
            font-size: 15.5px;
            color: $paragraph-color;
            font-weight: 500;
            margin-bottom: 0;
        }
        &.bg-DEEEFA {
            background-color: #DEEEFA;
        }
    }
}

/*================================================
IS Case Studies Area CSS
=================================================*/
.is-case-studies-area {
    background-color: #EAF0EF;

    .section-title-with-large-box {
        max-width: 100%;
        text-align: start;

        .max-width {
            max-width: 750px;
        }
        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
}
.is-case-studies-list-tabs {
    .nav {
        padding: 0;
        margin-bottom: 0;
        display: block;
        border: none;

        .nav-item {
            list-style-type: none;
            display: block;
            border: none;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            .nav-link {
                display: inline-block;
                padding: 20px 25px 20px 80px;
                background-color: $white-color;
                border-radius: 10px;
                font-size: 20px;
                font-weight: 600;
                color: $black-color;
                border: none;
                position: relative;
                width: 100%;
                transition: $transition;

                .number {
                    display: inline-block;
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                    background-color: $main-color;
                    color: $white-color;
                    text-align: center;
                    border-radius: 8px;
                    font-size: 35px;
                    font-weight: 600;
                    position: absolute;
                    left: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: $transition;
                }
                &:hover, &.active {
                    background-color: $main-color;
                    color: $white-color;

                    .number {
                        background-color: $white-color;
                        color: $main-color;
                    }
                }
            }
        }
    }
}
.is-case-studies-image {
    padding-left: 100px;
    position: relative;

    img {
        border-radius: 15px;
    }
    .content {
        background-color: $white-color;
        padding: 30px;
        border-radius: 10px;
        position: absolute;
        right: 0;
        bottom: -60px;
        max-width: 570px;

        p {
            margin-bottom: 15px;
        }
        a {
            color: $main-color;
        }
    }
}

/*================================================
IS Testimonials Area CSS
=================================================*/
.is-testimonials-area {
    background-color: $black-color;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .section-title-with-large-box {
        h2 {
            color: $white-color;
        }
    }
}
.is-testimonials-card {
    p {
        background-color: #0C1E34;
        padding: 35px;
        border-radius: 15px;
        color: $white-color;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: -1;
            color: $main-color;
            line-height: 95px;
            content: "\ee81";
            font-family: "boxicons" !important;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            font-size: 135px;
            opacity: 10%;
        }
    }
    .client-info {
        margin: {
            top: 30px;
            left: 30px;
        };
        img {
            width: auto;
            border-radius: 50px;
            max-width: 70px;
        }
        .title {
            margin-left: 15px;

            h3 {
                font-size: 20px;
                color: $white-color;
                margin-bottom: 0;
                font-weight: 600;
            }
            span {
                color: $main-color;
                display: inline-block;
                margin-top: 15px;
                font-size: 14px;
            }
        }
    }
}
.me-testimonials-shape1 {
    position: absolute;
    top: 10%;
    left: 5%;
    transform: translateY(-10%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-testimonials-shape2 {
    position: absolute;
    bottom: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
IS Team Area CSS
=================================================*/
.is-team-area {
    overflow: hidden;
    position: relative;
    z-index: 1;

    &.me-team {
        background: #F4F6FC;
    }
}
.is-team-content {
    padding-right: 50px;

    span {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    p {
        margin-bottom: 0;
    }
    .team-btn {
        margin-top: 25px;

        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
}
.is-inner-team-box {
    position: relative;
    z-index: 1;

    .team-vector-shape {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .col-lg-6 {
        &:nth-child(2) {
            margin-bottom: 30px;
        }
    }
}
.is-team-member {
    text-align: center;
    position: relative;
    overflow: hidden;

    .image {
        position: relative;

        .social-link {
            position: absolute;
            right: 20px;
            bottom: 100px;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    color: $black-color;
                    background-color: $white-color;
                    font-size: 18px;
                    position: relative;
                    border-radius: 8px;
                    transform: scaleY(0);
                    display: inline-block;
                    transition: .4s;

                    i {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        right: 0;
                        transform: translateY(-48%);
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                    a {
                        transform: scaleX(0);
                    }
                }
            }
        }
        img {
            border-radius: 10px;
        }
    }
    .content {
        background-color: $white-color;
        text-align: start;
        padding: 22px 75px 22px 20px;
        position: absolute;
        right: 0;
        bottom: 20px;
        border-radius: 10px 0 0 10px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        i {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: $main-color;
            transition: $transition;
            color: $white-color;
            border-radius: 50px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            display: inline-block;
        }
        &.with-position-relative {
            position: relative;
            border-radius: 10px;
            bottom: 0;
            margin-top: 15px;
        }
    }
    &:hover {
        .image {
            .social-link {
                li {
                    a {
                        transform: scale(1) !important;
                    }
                }
            }
        }
        .content {
            i {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.me-team-shape {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
IS Analysis Area CSS
=================================================*/
.is-analysis-area {
    background: #FEF9F9;
    overflow: hidden;
}
.is-analysis-image {
    position: relative;
    z-index: 1;

    img {
        border-radius: 10px;
    }
    .analysis-vector-shape {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
.is-analysis-content {
    padding-left: 45px;

    h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.3;
    }
    #contactForm {
        margin-top: 30px;
    }
    .form-group {
        margin-bottom: 25px;

        label {
            font-size: 14.5px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 12px;
        }
        .form-control {
            display: inline-block;
            background-color: $white-color;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            height: 55px;
            outline: 0;
            box-shadow: unset;
            padding: 15px 20px;
            color: $paragraph-color;
            transition: $transition;

            &::placeholder {
                color: $paragraph-color;
                transition: $transition;
            }
            &:focus {
                border: 1px solid $main-color;

                &::placeholder {
                    color: transparent;
                }
            }
        }
        textarea {
            &.form-control {
                min-height: 130px;
            }
        }
        .form-check-input {
            color: $paragraph-color;
            outline: 0;
            box-shadow: unset;
            width: 18px;
            height: 18px;
            margin-top: 2px;
            border-radius: 5px;
            margin-right: 5px;
        }
        .form-check-label {
            margin-bottom: 0;
            color: $paragraph-color;

            a {
                color: $main-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
    }
    .default-btn {
        background-color: $main-color;
        padding: 15px 30px;
        display: inline-flex;

        span {
            background-color: $optional-color;
        }
    }
    .text-danger {
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .list-unstyled {
        li {
            color: $optional-color;
            font-size: 14px;
        }
    }
}

/*================================================
Marketing Experts Banner Area CSS
=================================================*/
.me-banner-area {
    position: relative;
    z-index: 1;
    padding: {
        top: 50px;
        bottom: 50px;
    };
    overflow: hidden;
    border-top: 1px solid #E8EBEB;
    .container-fluid {
        padding: {
            left: 35px;
            right: 35px;
        };
    }
}
.me-banner-content {
    span {
        color: $main-color;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h1 {
        font-size: 75px;
        font-weight: bold;
        margin-bottom: 15px;
        background: linear-gradient(90deg, #FF512F 0%, #DD2476 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        line-height: 1.3;
    }
    p {
        max-width: 650px;
        margin-bottom: 0;
    }
    .banner-btn {
        padding: 0;
        margin: {
            top: 28px;
            bottom: 0;
        };
        display: flex;
        align-items: center;
        li {
            list-style-type: none;
            display: inline-flex;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .video-btn {
                color: $black-color;
                position: relative;
                display: inline-block;
                transition: .6s;
                font: {
                    size: 15px;
                    weight: 600;
                }
                i {
                    font-size: 20px;
                    position: relative;
                    margin-left: inherit;
                    background: linear-gradient(90deg, #8E2DE2 0%, #4A00E0 100%);
                    color: $white-color;
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 50%;
                    margin-right: 10px;
                    transition: $transition;
                }
            }
            .default-btn {
                background-color: $main-color;

                span {
                    background-color: $optional-color;
                }
            }
        }
    }
}
.me-banner-image {
    text-align: center;
    position: relative;
    z-index: 1;

    .circle-shape {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
.me-banner-shape {
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: -1;

    .line {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        background: #E8EBEB;
        overflow: hidden;
        opacity: 0.8;

        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 15vh;
            width: 100%;
            top: -50%;
            left: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $main-color 75%, $main-color 100%);
            animation: run 7s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
        }
        &:nth-child(1) {
            margin-left: -25%;

            &::after {
                animation-delay: 2s;
            }
        }
        &:nth-child(3) {
            margin-left: 25%;
        }
        &:nth-child(3)::after {
            animation-delay: 2.5s;
        }
    }
}
@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

/*================================================
ME Features Area CSS
=================================================*/
.me-features-card {
    margin-bottom: 30px;
    transition: $transition;
    padding-right: 10px;

    i {
        display: inline-block;
        height: 90px;
        width: 90px;
        line-height: 90px;
        background: linear-gradient(90deg, #FF512F 0%, #F09819 100%);
        color: $white-color;
        font-size: 45px;
        text-align: center;
        border-radius: 50px;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0;
    }
    &.bg-two {
        i {
            background: linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
        }
    }
    &.bg-three {
        i {
            background: linear-gradient(90deg, #7F00FF 0%, #E100FF 100%);
        }
    }
    &.bg-four {
        i {
            background: linear-gradient(90deg, #FF512F 0%, #DD2476 100%);
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}

/*================================================
ME About Area CSS
=================================================*/
.me-about-area {
    position: relative;
    z-index: 1;

    .container {
        border-top: 1px solid #E8EBEB;
    }
}
.me-about-content {
    padding-left: 30px;

    span {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.3;
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            font-size: 18px;
            color: $black-color;
            font-weight: 500;
            list-style-type: none;
            margin-bottom: 30px;
            position: relative;
            padding-left: 35px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                display: inline-block;
                height: 25px;
                width: 25px;
                line-height: 25px;
                background: linear-gradient(90deg, #8E2DE2 0%, #4A00E0 100%);
                text-align: center;
                border-radius: 30px;
                color: $white-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &.color-two {
                i {
                    background: linear-gradient(90deg, #FF512F 0%, #F09819 100%);
                }
            }
            &.color-three {
                i {
                    background: linear-gradient(90deg, #FF512F 0%, #DD2476 100%);
                }
            }
            &.color-four {
                i {
                    background: linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
                }
            }
        }
    }
    .about-btn {
        margin-top: 35px;

        .default-btn {
            background-color: $main-color;
            padding: 15px 30px;

            span {
                background-color: $optional-color;
            }
        }
    }
}
.me-about-shape1 {
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translateY(-10%) translateX(-10%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.me-about-shape2 {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
Me Choose Area CSS
=================================================*/
.me-choose-content {
    padding-right: 30px;

    span {
        color: $main-color;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 35px;
        margin-bottom: 15px;
        line-height: 1.3;
        font-weight: 600;
    }
    p {
        margin-bottom: 0;
    }
    .choose-inner-card {
        position: relative;
        margin-top: 25px;
        padding-left: 40px;

        i {
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background: linear-gradient(90deg, #8E2DE2 0%, #4A00E0 100%);
            text-align: center;
            border-radius: 30px;
            color: $white-color;
            position: absolute;
            left: 0;
            top: 2px;
        }
        h4 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 0;
        }
        &.bg-two {
            i {
                background: linear-gradient(90deg, #FF512F 0%, #F09819 100%);
            }
        }
        &.bg-three {
            i {
                background: linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area-with-black-color {
    background-color: $black-color;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .single-footer-widget {
        p {
            color: $white-color;
        }
        .social {
            li {
                a {
                    background-color: transparent;
                    border-radius: 8px;
                    border: 1px solid $white-color;
                    color: $white-color;
                    height: 35px;
                    width: 35px;
                    line-height: 36.2px;
                    transition: $transition;

                    &:hover {
                        background-color: $main-color;
                        border: 1px solid $main-color;
                        color: $white-color;
                    }
                }
            }
        }
        .services-list {
            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    position: relative;
                    color: $white-color;
                    padding-left: 18px;

                    &::before {
                        position: absolute;
                        content: "";
                        background-color: $main-color;
                        height: 10px;
                        width: 10px;
                        border-radius: 10px;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        color: $optional-color;
                    }
                }
            }
        }
        .support-list {
            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    position: relative;
                    color: $white-color;
                    padding-left: 18px;

                    &::before {
                        position: absolute;
                        content: "";
                        background-color: $main-color;
                        height: 10px;
                        width: 10px;
                        border-radius: 10px;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:hover {
                        color: $optional-color;
                    }
                }
            }
        }
        .footer-contact-info {
            li {
                color: $main-color;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: $white-color;

                    &:hover {
                        color: $optional-color;
                    }
                }
            }
        }
    }
}
.footer-shape-1 {
    position: absolute;
    top: 5%;
    left: 2%;
    transform: translateY(-5%) translateX(-2%);
    z-index: -1;
    max-width: 100px;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.footer-shape-2 {
    position: absolute;
    bottom: 10%;
    right: 5%;
    transform: translateY(-10%) translateX(-5%);
    z-index: -1;
    max-width: 100px;

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.bx.bx-spin {
  margin-top: -10px;
}
